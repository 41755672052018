<app-header
  *ngIf="user"
  [user]="user"
  serviceName="Google/Microsoft連携"
></app-header>

<main class="{{ iframe ? 'iframe' : '' }}">
  <spen-page-title
    [backNavigation]="{
      routerLink: '/school/sso',
      queryParamsHandling: 'preserve'
    }"
    >Google/Microsoft 一括連携設定</spen-page-title
  >

  <app-global-message [isMarginBottom]="true"></app-global-message>

  <div class="message">
    Google/Microsoftの連携を利用する先生、生徒を、登録・編集します。
  </div>

  <section class="csv download">
    <spen-heading [level]="2" headingStyle="size-s" [hasMarginBottom]="true"
      ><p class="title">CSVのダウンロード</p></spen-heading
    >
    <div>
      先生または生徒を選び、Google/Microsoft連携を設定したい年度、学校種別、学年、クラスを選択して、ファイルをダウンロードしてください。
    </div>
    <app-download-form-sso-users
      *ngIf="searchOptions"
      [schoolYears]="searchOptions.school_years"
      [schoolStages]="searchOptions.stages"
      [grades]="searchOptions.grades"
      [classrooms]="searchOptions.classrooms"
      (changeSchoolYear)="updateSearchOptions($event)"
    ></app-download-form-sso-users>
  </section>

  <section class="csv upload">
    <spen-heading [level]="2" headingStyle="size-s" [hasMarginBottom]="true"
      ><p class="title">CSVファイルをアップロード</p></spen-heading
    >
    <div>
      Google または
      Microsoftのアカウント情報を入力し、ファイルをアップロードしてください。
    </div>
    <app-upload-form-sso-users></app-upload-form-sso-users>
  </section>
</main>

<app-footer [user]="user"></app-footer>
