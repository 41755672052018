<div class="upload-error" *ngIf="formErrors?.file as uploadError">
  <spen-flash-message>
    {{ uploadError }}
  </spen-flash-message>
</div>
<form [formGroup]="form" (submit)="upload()">
  <div class="form-field">
    <spen-label>ファイルの種類</spen-label>
    <spen-select
      formControlName="account_type"
      [optionList]="accountTypeList"
      [defaultTextType]="'text'"
    ></spen-select>
  </div>
  <div class="form-field">
    <div class="file-input">
      <button spenButton type="button" (click)="selectCsvInput.click()">
        ファイル選択
      </button>
      <input
        #selectCsvInput
        formControlName="input_csv"
        type="file"
        accept=".csv"
        (change)="onFileSelected($event)"
      />
    </div>
    <div class="file-name">
      {{
        form.controls.file.value
          ? form.controls.file.value.name
          : 'ファイルを選択してください'
      }}
    </div>
  </div>

  <div class="actions">
    <button
      color="primary"
      spenButton
      class="action-button"
      [disabled]="form.invalid || uploadInProgress"
    >
      {{ uploadInProgress ? 'アップロード中' : 'ファイルをアップロードする' }}
    </button>
  </div>
</form>
