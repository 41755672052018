/**
 * @see https://github.com/classi/angular-devkit/blob/main/projects/ngrx-extensions/README.md#store-createfeatureslice
 */

import { createFeatureSlice, PayloadAction } from '@classi/ngrx-extensions';
import { CurrentFilterSet, SsoUsersList } from '../api/types';

type State = {
  currentFilter: CurrentFilterSet | null;
  usersList: SsoUsersList | null;
};

const initialState: State = {
  currentFilter: null,
  usersList: null,
};

export default createFeatureSlice({
  name: 'ssoTeachers',
  initialState,
  reducers: {
    setCurrentFilterAndUsers: (
      current: State,
      action: PayloadAction<{
        currentFilter: CurrentFilterSet;
        usersList: SsoUsersList;
      }>
    ) => {
      return {
        currentFilter: action.payload.currentFilter,
        usersList: action.payload.usersList,
      };
    },
    reset: () => ({ currentFilter: null, usersList: null }),
  },
});
