<app-header *ngIf="title" [serviceName]="title"></app-header>

<main>
  <spen-heading [level]="2" headingStyle="size-l"
    >Classiのご利用にあたって</spen-heading
  >
  <app-global-message></app-global-message>

  <p class="notes">
    「Classi」をご利用いただくには、以下の{{
      title
    }}への同意が必要です。よくお読みいただき、同意いただいた上で、ご利用ください。
  </p>

  <spen-heading [level]="2" headingStyle="size-s"
    >Classi {{ title }}</spen-heading
  >

  <div class="tos">
    <iframe *ngIf="tosUrl" [src]="tosUrl"></iframe>
  </div>

  <form (submit)="onTosFormSubmit()" [formGroup]="tosForm">
    <!-- eulaなら同意するチェックボックスを表示 -->
    <spen-checkbox *ngIf="tosType === 'eula'" formControlName="agreement"
      >Classi {{ title }}に同意する</spen-checkbox
    >
    <div class="form-actions">
      <button
        color="primary"
        spenButton
        class="action-button"
        [disabled]="tosForm.invalid || submitInProgress"
      >
        次へ
      </button>
    </div>
  </form>
</main>

<app-footer></app-footer>
