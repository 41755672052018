<div
  *ngIf="globalMessage$ | async as globalMessage"
  class="global-message {{ globalMessage.status }} {{
    isMarginTop ? 'with-margin-top' : ''
  }} {{ isMarginBottom ? 'with-margin-bottom' : '' }}"
>
  <spen-flash-message>
    <div>{{ globalMessage.message.general }}</div>
    <div class="hint-link" *ngIf="globalMessage.message.hint_link as hintLink">
      {{ hintLink.label }}：<a
        spenLink
        href="{{ hintLink.url }}"
        target="_blank"
        rel="noopener noreferrer"
        >{{ hintLink.title }}</a
      >
    </div>
    <div class="inquiry" *ngIf="globalMessage.message.displayInquiry">
      <p>■先生専用電話窓口</p>
      <p>フリーダイヤル: 0120-755-640</p>
      <p>平日8:00〜19:00 土曜8:00〜17:00 ※日曜祝日、年末・年始を除く</p>
    </div>
  </spen-flash-message>
</div>
