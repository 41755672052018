import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CurrentFilterSet, SsoUsersList } from './api/types';
import ssoStudentsSlice from './store/sso-students.slice';

@Injectable({
  providedIn: 'root',
})
export class SsoStudentsService {
  constructor(private readonly store: Store<unknown>) {}

  setCurrentFilterAndStudents(
    currentFilter: CurrentFilterSet,
    usersList: SsoUsersList
  ): void {
    this.store.dispatch(
      ssoStudentsSlice.actions.setCurrentFilterAndUsers({
        currentFilter: currentFilter,
        usersList: usersList,
      })
    );
  }
  getCurrentFilterAndStudents(): Observable<{
    currentFilter: CurrentFilterSet;
    usersList: SsoUsersList;
  }> {
    return ssoStudentsSlice.select(this.store, (state: any) => state);
  }
}
