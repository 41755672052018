import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { SpenFlashMessageModule, SpenLinkModule } from '@classi/spen';
import { GlobalMessageService } from '../global-message.service';

@Component({
  standalone: true,
  selector: 'app-global-message',
  imports: [CommonModule, SpenFlashMessageModule, SpenLinkModule],
  templateUrl: './global-message.component.html',
  styleUrls: ['./global-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalMessageComponent {
  @Input() isMarginTop: boolean = true;
  @Input() isMarginBottom: boolean = true;
  readonly globalMessage$ = this.globalMessageService.get();
  constructor(private readonly globalMessageService: GlobalMessageService) {}
}
