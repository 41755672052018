import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SpenFormFieldModule, SpenModule } from '@classi/spen';
import { ReactiveFormsModule } from '@angular/forms';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginIdentifierComponent } from './pages/login-identifier/login-identifier.component';
import { LoginChallengeComponent } from './pages/login-challenge/login-challenge.component';
import { LoginTosAgreementComponent } from './pages/login-tos-agreement/login-tos-agreement.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import loginChallengeSlice from './shared/store/login-challenge.slice';
import globalMessageSlice from './shared/store/global-message.slice';
import { PasswordLoginChallengeComponent } from './pages/login-challenge/password/password-login-challenge.component';
import { LoginCompleteComponent } from './pages/login-complete/login-complete.component';
import { httpInterceptorProviders } from './http-interceptors';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';
import { GlobalMessageComponent } from './shared/global-message/global-message.component';
import { SchoolSettingComponent } from './pages/school-setting/school-setting.component';
import { AccountTypeNamePipe } from './shared/pipes/account-type-name.pipe';
import { CurrentUserPipe } from './shared/pipes/current-user.pipe';
import { PasswordNotesComponent } from './shared/password-notes/password-notes.component';
import { LoginInitialSettingComponent } from './pages/login-initial-setting/login-initial-setting.component';
import { LoginSettingComponent } from './pages/login-setting/login-setting.component';
import { UsernameSettingFormComponent } from './pages/login-setting/username-setting-form/username-setting-form.component';
import { PasswordSettingFormComponent } from './pages/login-setting/password-setting-form/password-setting-form.component';
import { ConfirmPasswordDialogComponent } from './shared/confirm-password-dialog/confirm-password-dialog.component';
import ssoTeachersSlice from './shared/store/sso-teachers.slice';
import ssoStudentsSlice from './shared/store/sso-students.slice';
import { SchoolSsoComponent } from './pages/school-sso/school-sso.component';
import { SsoUsersSearchFieldComponent } from './pages/school-sso/search-field/sso-users-search-field.component';
import { SsoTeachersComponent } from './pages/school-sso/teachers/sso-teachers.component';
import { SsoStudentsComponent } from './pages/school-sso/students/sso-students.component';
import { SsoUsersListComponent } from './pages/school-sso/users-list/sso-users-list.component';
import { SsoUsersAccountEditDialogComponent } from './pages/school-sso/users-list/account-edit-dialog/sso-users-account-edit-dialog.component';
import { LoginChooseAccountComponent } from './pages/login-choose-account/login-choose-account.component';
import { SchoolSsoUsersSettingComponent } from './pages/school-sso-users-setting/school-sso-users-setting.component';
import { DownloadFormSsoUsersComponent } from './pages/school-sso-users-setting/download-form/download-form-sso-users.component';
import { UploadFormSsoUsersComponent } from './pages/school-sso-users-setting/upload-form/upload-form-sso-users.component';
import { LoginContinueComponent } from './pages/login-continue/login-continue.component';
import { LoginProfileConfirmationComponent } from './pages/login-profile-confirmation/login-profile-confirmation.component';
import passwordResetSlice from './shared/store/password-reset.slice';
import { KaisatsuErrorHandler } from './shared/kaisatsu-error-handler';
import { SchoolManabiComponent } from './pages/school-manabi/school-manabi.component';
import { DownloadFormManabiComponent } from './pages/school-manabi/download-form/download-form-manabi.component';
import { BcLoginManabiBeginComponent } from './pages/bc-login-manabi-begin/bc-login-manabi-begin.component';
import { BcLoginManabiChallengeComponent } from './pages/bc-login-manabi-challenge/bc-login-manabi-challenge.component';
import { PasswordBcLoginManabiChallengeComponent } from './pages/bc-login-manabi-challenge/password/password-bc-login-manabi-challenge.component';
import { BcLoginManabiCompleteComponent } from './pages/bc-login-manabi-complete/bc-login-manabi-complete.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginChallengeComponent,
    LoginTosAgreementComponent,
    PasswordLoginChallengeComponent,
    LoginCompleteComponent,
    LoginInitialSettingComponent,
    LoginSettingComponent,
    UsernameSettingFormComponent,
    PasswordSettingFormComponent,
    SchoolSsoComponent,
    SsoUsersSearchFieldComponent,
    SsoTeachersComponent,
    SsoStudentsComponent,
    SsoUsersListComponent,
    SsoUsersAccountEditDialogComponent,
    LoginChooseAccountComponent,
    SchoolSsoUsersSettingComponent,
    DownloadFormSsoUsersComponent,
    UploadFormSsoUsersComponent,
    LoginContinueComponent,
    LoginProfileConfirmationComponent,
    SchoolManabiComponent,
    DownloadFormManabiComponent,
    BcLoginManabiChallengeComponent,
    PasswordBcLoginManabiChallengeComponent,
    BcLoginManabiCompleteComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SpenFormFieldModule,
    SpenModule,
    ReactiveFormsModule,
    // pages
    LoginIdentifierComponent,
    BcLoginManabiBeginComponent,
    // shared
    HeaderComponent,
    FooterComponent,
    CurrentUserPipe,
    GlobalMessageComponent,
    ConfirmPasswordDialogComponent,
    PasswordNotesComponent,
    AccountTypeNamePipe,
    // shared store
    StoreModule.forRoot({}),
    StoreModule.forFeature(loginChallengeSlice),
    StoreModule.forFeature(globalMessageSlice),
    StoreModule.forFeature(ssoTeachersSlice),
    StoreModule.forFeature(ssoStudentsSlice),
    StoreModule.forFeature(passwordResetSlice),
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
  ],
  providers: [
    httpInterceptorProviders,
    {
      provide: ErrorHandler,
      useClass: KaisatsuErrorHandler,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
