import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../shared/api/login.service';
import {
  FormErrors,
  KaisatsuResponseError,
} from '../../shared/api/response.error';
import { confirmationMatchValidator } from '../../shared/validators/confirmation-match';
import { GlobalMessageService } from '../../shared/global-message.service';
import { PasswordParams } from '../../shared/api/types';
import { kaisatsuHandleError } from '../../shared/kaisatsu-error-handler';
import { Title } from '@angular/platform-browser';

export type LoginInitialSetting = PasswordParams;

@Component({
  selector: 'app-login-initial-setting',
  templateUrl: './login-initial-setting.component.html',
  styleUrls: ['./login-initial-setting.component.scss'],
})
export class LoginInitialSettingComponent implements OnInit, OnDestroy {
  readonly form = this.fb.group(
    {
      new_password: this.fb.control('', {
        validators: [Validators.required],
      }),
      new_password_confirmation: this.fb.control('', {}),
    },
    {
      validators: [
        confirmationMatchValidator('new_password', 'new_password_confirmation'),
      ],
    }
  );

  isPasswordVisible = false;
  submitInProgress = false;
  formErrors: FormErrors | null = null;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly router: Router,
    private readonly loginService: LoginService,
    private readonly globalMessageService: GlobalMessageService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.route.snapshot.data.title);
  }

  ngOnDestroy() {
    this.globalMessageService.delete();
  }

  get newPasswordConfirmation() {
    return this.form.get('new_password_confirmation');
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  async onSubmit(): Promise<void> {
    this.submitInProgress = true;
    try {
      await this.loginService.postLoginInitialSetting(
        this.form.value as LoginInitialSetting
      );
      this.router.navigateByUrl('/login/continue');
    } catch (e: KaisatsuResponseError | unknown) {
      if (e instanceof KaisatsuResponseError) {
        // グローバルに表示するエラーメッセージをここで保存
        this.globalMessageService.setErrorMessage(e.messages);
        // フォームに関するエラーがある場合はフォームに渡す
        if (e.messages?.form) {
          this.formErrors = e.messages.form;
        }
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
    }
    this.submitInProgress = false;
  }
}
