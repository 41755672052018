<app-sso-users-search-field
  *ngIf="searchOptions"
  [accountType]="'student'"
  [schoolYears]="searchOptions.school_years"
  [schoolStages]="searchOptions.stages"
  [grades]="searchOptions.grades"
  [classrooms]="searchOptions.classrooms"
  [currentFilter]="currentFilter"
  [submitInProgress]="searchInProgress"
  (searchSubmit)="searchStudents($event)"
  (currentFilterUpdate)="currentFilterUpdate($event)"
></app-sso-users-search-field>

<app-sso-users-list
  [accountType]="'student'"
  [usersList]="studentsList"
  (pageChange)="getPageStudents($event)"
  (ssoUserUpdate)="refreshStudents()"
></app-sso-users-list>
