<app-header
  *ngIf="user"
  [user]="user"
  serviceName="Google/Microsoft連携"
></app-header>

<main class="{{ iframe ? 'iframe' : '' }}">
  <spen-heading [level]="2" [headingStyle]="'size-l'" [hasMarginBottom]="true"
    >Google/Microsoft連携</spen-heading
  >

  <app-global-message [isMarginBottom]="true"></app-global-message>

  <section class="description">
    <div class="notice">
      初めて連携設定される方はこちらの手順書をご確認ください。<a
        spenLink
        href="https://cdn.classi.jp/manuals/howto_sso_setting.pdf"
        target="_blank"
        rel="noopener"
        >【Classi】Google_Microsoft連携設定.pdf<i class="fa fa-download"></i
      ></a>
    </div>
    <div class="setting-button">
      <a
        spenButton
        [routerLink]="'/school/sso/users_setting'"
        queryParamsHandling="preserve"
        >一括連携設定へ</a
      >
    </div>
  </section>

  <spen-tab
    [tabList]="tabList"
    [selectedTabName]="selectedTabName"
    (clickTab)="clickTab($event)"
  ></spen-tab>

  <app-sso-teachers
    *ngIf="teacherSearchOptions && selectedTabName === 'teacher'"
    [searchOptions]="teacherSearchOptions"
    (schoolYearChange)="teacherSchoolYearChange($event)"
  ></app-sso-teachers>

  <app-sso-students
    *ngIf="studentSearchOptions && selectedTabName === 'student'"
    [searchOptions]="studentSearchOptions"
    (schoolYearChange)="studentSchoolYearChange($event)"
  ></app-sso-students>
</main>

<app-footer [user]="user"></app-footer>
