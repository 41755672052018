<div class="panel">
  <form [formGroup]="form" (submit)="onSubmit()">
    <section class="school">
      <div class="panel-line">
        <div class="form-field">
          <spen-label>年度</spen-label>
          <spen-select
            [optionList]="schoolYearOptions"
            [responsive]="true"
            [selectedItemValue]="currentFilter.school_year"
            (changeSelectOption)="changeSchoolYear($event)"
          ></spen-select>
        </div>
        <div class="form-field">
          <spen-label>学校種別</spen-label>
          <spen-select
            [optionList]="schoolStageOptions"
            [selectedItemValue]="currentFilter.stage_id"
            [responsive]="true"
            (changeSelectOption)="changeStage($event)"
          ></spen-select>
        </div>
        <div class="form-field">
          <spen-label>学年</spen-label>
          <ng-container
            *ngIf="isGradeEnabled; then enabledGrade; else disabledGrade"
          ></ng-container>
          <ng-template #enabledGrade>
            <spen-select
              [optionList]="gradeOptions"
              [selectedItemValue]="currentFilter.grade_id"
              (changeSelectOption)="changeGrade($event)"
              [responsive]="true"
            ></spen-select>
          </ng-template>
          <ng-template #disabledGrade>
            <spen-select [responsive]="true" [disabled]="true"></spen-select>
          </ng-template>
        </div>
      </div>
      <div class="panel-line" *ngIf="accountType === 'student'">
        <div class="form-field" *ngIf="accountType === 'student'">
          <spen-label>クラス</spen-label>
          <ng-container
            *ngIf="
              isClassroomEnabled;
              then enabledClassroom;
              else disabledClassroom
            "
          ></ng-container>
          <ng-template #enabledClassroom>
            <spen-select
              [optionList]="classroomOptions"
              [selectedItemValue]="currentFilter.classroom_id"
              (changeSelectOption)="changeClassroom($event)"
              [responsive]="true"
            ></spen-select>
          </ng-template>
          <ng-template #disabledClassroom>
            <spen-select [disabled]="true" [responsive]="true"></spen-select>
          </ng-template>
        </div>
        <div class="form-field" *ngIf="accountType === 'student'">
          <spen-label>番号</spen-label>
          <div>
            <input
              class="attendance_number"
              spenInput
              formControlName="attendance_number"
              type="number"
              min="1"
              [disabled]="!isAttendanceNumberEnabled"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="sso">
      <div class="panel-line">
        <div class="form-field">
          <spen-label class="sso">Google Microsoft アカウント</spen-label>
          <div>
            <input
              spenInput
              formControlName="email"
              placeholder="入力してください"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="actions">
      <button
        color="primary"
        spenButton
        class="action-button"
        [disabled]="submitInProgress"
      >
        検索する
      </button>
    </section>
  </form>
</div>
