import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpContextToken,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ajax, AjaxError, AjaxResponse, AjaxTimeoutError } from 'rxjs/ajax';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

/**
 * CSRFトークンを取得したくないリクエストがあれば
 * this.http.post<>(
    url,
    params,
    { context: this.httpContext.set(DISABLE_CSRF_TOKEN_VALIDATION, true) }
    )...
  * でcontextオプションを渡す
  */
export const DISABLE_KAISATSU_CSRF_TOKEN_VALIDATION =
  new HttpContextToken<boolean>(() => false);

@Injectable()
export class KaisatsuCsrfTokenInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const requestUrlOrigin = environment.kaisatsuApiBaseUrl
      ? new URL(request.url).origin
      : '';

    if (
      request.method === 'GET' ||
      requestUrlOrigin !== environment.kaisatsuApiBaseUrl ||
      request.context.get(DISABLE_KAISATSU_CSRF_TOKEN_VALIDATION)
    ) {
      return next.handle(request);
    }

    const tokenResponse$: Observable<AjaxResponse> = ajax({
      url: `${environment.kaisatsuApiBaseUrl}/api/v1/csrf_token`,
      responseType: 'json',
      withCredentials: true,
    });

    return tokenResponse$.pipe(
      switchMap((response) => {
        const newReq = request.clone({
          headers: request.headers.append(
            'X-CSRF-Token',
            response.response?.data
          ),
        });
        return next.handle(newReq);
      }),
      catchError((error) => {
        if (error instanceof AjaxError || error instanceof AjaxTimeoutError) {
          const iframe = this.route.snapshot.queryParamMap.get('iframe');
          switch (error.status) {
            case 503:
              if (iframe) {
                this.router.navigate(['/503'], {
                  queryParams: { iframe: true },
                });
              } else {
                this.router.navigateByUrl('/503');
              }
              break;
            case 0:
              // ログインフローにいる場合はログイントップに戻す
              if (window.location.pathname.startsWith('/login/')) {
                this.router.navigate(['/login/identifier']);
              }
          }
        }
        return throwError(error);
      })
    );
  }
}
