import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

/**
 *
 * @see https://mswjs.io/docs/recipes/deferred-mounting
 *
 */

async function prepare() {
  if (environment.kaisatsuApiBaseUrl === '') {
    const { worker } = await import('./mocks/browser');
    return await worker.start();
  }
  return;
}

if (environment.sentry !== null) {
  Sentry.init({
    dsn: environment.sentry.dsn,
    environment: environment.sentry.environment,
    release: environment.sentry.release,
    integrations: [new BrowserTracing({})],
    tracesSampleRate: 0.001,
    ignoreErrors: [
      /\bCan't find variable: removeAllHighlightsForSB\b/,
      /\bCan't find variable: MyApp_RemoveAllHighlights\b/,
      /\bCan't find variable: WKWebView_RemoveAllHighlights\b/,
      /\bonSvFinishLoading is not defined\b/,
      'window.onorientationchange is not a function',
      // GAから出てるエラー
      /^L$/,
      /^N$/,
    ],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
    ],
  });
}

prepare()
  .then(() => {
    return platformBrowserDynamic().bootstrapModule(AppModule);
  })
  .catch((err) => console.error(err));
