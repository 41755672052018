import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SpenHeaderModule, SpenLinkModule } from '@classi/spen';
import { environment } from '../../../environments/environment';
import { User } from '../api/types';
import { CurrentUserPipe } from '../pipes/current-user.pipe';

@Component({
  standalone: true,
  selector: 'app-header',
  imports: [CommonModule, SpenHeaderModule, CurrentUserPipe],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Input()
  logoHeader = false;
  @Input()
  serviceName: string = '';
  @Input()
  user: User | null = null;

  platformReferrerUrl = environment.platformReferrerUrl;
  constructor(private readonly route: ActivatedRoute) {}

  noHeaderRequired(): boolean {
    // iframe=trueのパラメータがある場合はヘッダーを非表示にする
    return this.route.snapshot.queryParamMap.get('iframe') === 'true';
  }
}
