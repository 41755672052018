/**
 * @see https://github.com/classi/angular-devkit/blob/main/projects/ngrx-extensions/README.md#store-createfeatureslice
 */

import { createFeatureSlice } from '@classi/ngrx-extensions';
import { PayloadAction } from '@reduxjs/toolkit';

export type State = {
  username: string | null;
  address?: string | null;
};

const initialState: State = {
  username: null,
  address: null,
};

export default createFeatureSlice({
  name: 'passwordReset',
  initialState,
  reducers: {
    setInformation: (
      current: State,
      action: PayloadAction<{
        username: string;
        address?: string;
      }>
    ) => {
      return {
        username: action.payload.username,
        address: action.payload.address,
      };
    },
  },
});
