import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { handleErrorResponse } from './response.error';
import { Email, PasswordParams, User } from './types';

const baseUrl = `${environment.kaisatsuApiBaseUrl}/api`;
@Injectable({
  providedIn: 'root',
})
export class MyService {
  constructor(private http: HttpClient) {}

  /**
   * @see https://kaisatsu.stg.classi.jp/docs/api/index.html
   */

  async getStatuses(): Promise<User> {
    const response = await this.http
      .get<{ success: true; data: User }>(`${baseUrl}/v1/my/statuses`)
      .toPromise()
      .catch(handleErrorResponse);

    return response.data;
  }

  async getSudo(password: { password: string }): Promise<void> {
    await this.http
      .post<{
        success: true;
      }>(`${baseUrl}/v1/my/session/sudo`, password)
      .toPromise()
      .catch(handleErrorResponse);
  }

  async putUsername(new_username: { new_username: string }): Promise<void> {
    const response = await this.http
      .put<{
        success: true;
      }>(`${baseUrl}/v1/my/username`, new_username)
      .toPromise()
      .catch(handleErrorResponse);
  }

  async putPassword(params: PasswordParams): Promise<void> {
    await this.http
      .put<{
        success: true;
      }>(`${baseUrl}/v1/my/password`, params)
      .toPromise()
      .catch(handleErrorResponse);
  }

  async getEmail(): Promise<{ email: Email | null }> {
    const response = await this.http
      .get<{
        success: true;
        data: { email: Email | null };
      }>(`${baseUrl}/v1/my/email`)
      .toPromise()
      .catch(handleErrorResponse);

    return response.data;
  }

  async deleteEmail(): Promise<void> {
    await this.http
      .delete<{
        success: true;
      }>(`${baseUrl}/v1/my/email`)
      .toPromise()
      .catch(handleErrorResponse);
  }

  async getEmailConfirmationCode(params: { email: string }): Promise<void> {
    await this.http
      .post<{
        success: true;
      }>(`${baseUrl}/v1/my/email_confirmation_code`, params)
      .toPromise()
      .catch(handleErrorResponse);
  }

  async postEmailAndConfirmationCode(params: {
    email: string;
    confirmation_code: string;
  }): Promise<void> {
    await this.http
      .post<{
        success: true;
      }>(`${baseUrl}/v1/my/email`, params)
      .toPromise()
      .catch(handleErrorResponse);
  }
}
