<ng-container *ngIf="renderPage">
  <app-header [logoHeader]="true" serviceName="ログイン画面"></app-header>

  <main>
    <app-global-message></app-global-message>

    <section class="login-box">
      <div class="login classi">
        <app-login-identifier-form
          [submitInProgress]="submitInProgress"
          [formErrors]="formErrors"
          (login)="getLogin($event)"
        ></app-login-identifier-form>
      </div>

      <div class="divider"></div>

      <div class="login others">
        <app-login-benesse></app-login-benesse>
        <app-login-sso *ngIf="!isThirdPartyLogin"></app-login-sso>
      </div>
    </section>
    <p class="notice" *ngIf="!isThirdPartyLogin">
      ログインできない方は<a
        spenLink
        href="https://support.classi.jp/hc/ja/articles/360002278495-Classiにログインできない"
        target="_blank"
        rel="noopener noreferrer"
        >こちら</a
      >をご確認ください。
    </p>
  </main>

  <app-footer></app-footer>
</ng-container>
