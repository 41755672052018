<app-header
  [logoHeader]="true"
  serviceName="マナビジョンログイン画面"
></app-header>

<main *ngIf="username">
  <app-global-message></app-global-message>
  <section class="challenge-box">
    <div class="id">
      <div class="label-and-value">
        <spen-label class="label">Classi ID</spen-label>
        <p class="value">{{ username }}</p>
      </div>
      <div>
        <a
          class="link-back"
          spenLink
          routerLink="/bc_login/manabi/begin"
          queryParamsHandling="preserve"
          >変更</a
        >
      </div>
    </div>

    <div *ngIf="getPasswordLoginMethod() as loginMethod" class="password">
      <spen-heading [level]="3">パスワードを入力 </spen-heading>

      <app-password-bc-login-manabi-challenge
        [password]="password"
        [loginMethod]="loginMethod"
        [formErrors]="formErrors"
        [submitInProgress]="submitInProgress"
        (challenge)="onPasswordLoginChallenge($event)"
      ></app-password-bc-login-manabi-challenge>
    </div>
  </section>
</main>

<app-footer></app-footer>
