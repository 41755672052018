import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { SpenSelectOption, SpenSelectValue } from '@classi/spen';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SchoolAdminService } from '../../../shared/api/school-admin.service';
import {
  Classroom,
  Grade,
  SchoolStage,
  SearchSchoolYear,
} from '../../../shared/api/types';
import { kaisatsuHandleError } from '../../../shared/kaisatsu-error-handler';
import { KaisatsuResponseError } from '../../../shared/api/response.error';
import { GlobalMessageService } from '../../../shared/global-message.service';

export type GetManabiStudentsCsvParams = {
  school_year: number;
  stage_id?: number;
  grade_id?: number;
};

@Component({
  selector: 'app-download-form-manabi',
  templateUrl: './download-form-manabi.component.html',
  styleUrls: ['./download-form-manabi.component.scss'],
})
export class DownloadFormManabiComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input()
  schoolYears: SearchSchoolYear[] = [];
  @Input()
  schoolStages: SchoolStage[] = [];
  @Input()
  grades: Grade[] = [];
  @Input()
  classrooms: Classroom[] = [];

  schoolYearOptions: SpenSelectOption[] = [];
  schoolStageOptions: SpenSelectOption[] = [];
  gradeOptions: SpenSelectOption[] = [];
  downloadInProgress = false;

  readonly form = this.fb.group({
    school_year: this.fb.control(null, { validators: [Validators.required] }),
    stage_id: this.fb.control(null, {}),
    grade_id: this.fb.control(null, {}),
  });

  onDestroy$ = new Subject();

  @Output()
  changeSchoolYear = new EventEmitter<SpenSelectValue>();

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly schoolAdminService: SchoolAdminService,
    private readonly globalMessageService: GlobalMessageService
  ) {}

  ngOnInit(): void {
    this.buildSelectBoxOptions();
    this.setSelectedSchoolYear();
    this.form.controls.stage_id.setValue(2);

    this.form.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.buildSelectBoxOptions();
    });
  }

  ngOnChanges(): void {
    this.buildSelectBoxOptions();
    this.setSelectedSchoolYear();
    this.form.controls.stage_id.setValue(2);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  get isGradeEnabled(): boolean {
    return this.gradeOptions.length > 0;
  }

  selectStage() {
    this.form.controls.grade_id.setValue(null);
  }

  async download(): Promise<void> {
    this.downloadInProgress = true;
    const accountType = this.form.value.account_type;
    try {
      await this.schoolAdminService.getManabiStudentsZip(
        this.form.value as GetManabiStudentsCsvParams
      );
    } catch (e: KaisatsuResponseError | unknown) {
      if (e instanceof KaisatsuResponseError) {
        this.globalMessageService.setErrorMessage(e.messages);
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
    }
    this.downloadInProgress = false;
  }

  private setSelectedSchoolYear(): void {
    const selectedSchoolYear = this.schoolYears.find(
      (school_year) => school_year.selected
    );
    if (selectedSchoolYear) {
      this.form.controls.school_year.setValue(selectedSchoolYear.year);
    }
  }

  private buildSelectBoxOptions() {
    this.buildSchoolYearOptions();
    this.buildSchoolStageOptions();
    this.buildGradeOptions();
  }

  private buildSchoolYearOptions() {
    this.schoolYearOptions = this.schoolYears.map((schoolYear) => ({
      text: `${schoolYear.year}`,
      value: schoolYear.year,
    }));
  }

  private buildSchoolStageOptions() {
    this.schoolStageOptions = this.schoolStages.map((schoolStage) => ({
      text: schoolStage.name,
      value: schoolStage.id,
    }));
  }

  private buildGradeOptions() {
    if (this.form.value.stage_id) {
      const grades = this.grades.filter(
        (grade) => grade.stage_id === this.form.value.stage_id
      );

      this.gradeOptions = this.buildFilterItemList(grades);
    } else {
      this.gradeOptions = [];
    }
  }

  private buildFilterItemList<T extends { id: number; name: string }>(
    entities: T[]
  ): SpenSelectOption[] {
    if (entities.length === 0) {
      return [];
    }
    const items = entities.map((g) => ({ text: g.name, value: g.id }));
    return [{ text: 'すべて', value: null }, ...items];
  }
}
