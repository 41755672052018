<spen-dialog #confirmPasswordDialog>
  <spen-dialog-title>確認</spen-dialog-title>

  <spen-flash-message *ngIf="errorMessage">
    {{ errorMessage }}
  </spen-flash-message>

  <section class="current-password">
    <p>{{ title }}にはログイン時に使用中の現在のパスワードの入力が必要です。</p>
    <form [formGroup]="form">
      <div class="form-field">
        <spen-label>現在のパスワード</spen-label>
        <spen-form-field>
          <input
            spenInput
            formControlName="password"
            type="{{ isPasswordVisible ? 'text' : 'password' }}"
            placeholder="入力してください"
            autocapitalize="off"
            autocorrect="off"
          />
          <button
            spenLink
            spenSuffix
            type="button"
            class="is-password-visible"
            (click)="togglePasswordVisibility()"
          >
            {{ isPasswordVisible ? '非表示' : '表示' }}
          </button>
          <spen-form-error *ngIf="formErrors?.password as passwordError">{{
            passwordError
          }}</spen-form-error>
        </spen-form-field>
      </div>
    </form>
    <div class="notice">
      ※現在のパスワードを忘れてしまった場合、パスワードの初期化が必要です。<br />
      ※Classi窓口では利用者の皆様の情報の確認や変更ができません。大変お手数をおかけいたしますが、先生に初期化の対応をご依頼ください。
    </div>
  </section>

  <spen-dialog-primary-action
    [disabled]="form.invalid || submitInProgress"
    (action)="onSubmit()"
    >実行する</spen-dialog-primary-action
  >
</spen-dialog>
