<header *ngIf="!noHeaderRequired()">
  <!-- ログイン画面で使うロゴのヘッダー -->
  <div *ngIf="logoHeader" class="logo-header">
    <img class="logo" src="../assets/img/classi_logo.svg" />
    <h1 class="heading">{{ serviceName }}</h1>
  </div>

  <!-- ログイン前の画面で使うspenデザインのヘッダー -->
  <div *ngIf="!logoHeader" class="before-login-header">
    <div class="logo"></div>
    <div class="title">{{ serviceName }}</div>
  </div>
</header>
