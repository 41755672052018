<app-header serviceName="初回ログイン情報設定"></app-header>

<main>
  <spen-heading [level]="2" [headingStyle]="'size-l'"
    >初回ログイン情報設定</spen-heading
  >
  <app-global-message></app-global-message>
  <form (submit)="onSubmit()" [formGroup]="form">
    <section class="password">
      <spen-heading [level]="2" [headingStyle]="'size-s'"
        >パスワードの設定</spen-heading
      >
      <div class="description">
        以下のルールに沿って、新しいパスワードを設定してください。
        <app-password-notes></app-password-notes>
      </div>

      <div class="form-field">
        <spen-label>新しいパスワード</spen-label>
        <spen-form-field>
          <input
            spenInput
            formControlName="new_password"
            type="{{ isPasswordVisible ? 'text' : 'password' }}"
            autocomplete="new-password"
            placeholder="入力してください"
            autocapitalize="off"
            autocorrect="off"
          />
          <button
            spenLink
            spenSuffix
            type="button"
            class="is-password-visible"
            (click)="togglePasswordVisibility()"
          >
            {{ isPasswordVisible ? '非表示' : '表示' }}
          </button>
          <spen-form-error
            *ngIf="formErrors?.new_password as newPasswordError"
            >{{ newPasswordError }}</spen-form-error
          >
        </spen-form-field>
      </div>
      <div class="form-field">
        <spen-label>新しいパスワード（確認用）</spen-label>
        <spen-form-field>
          <input
            spenInput
            formControlName="new_password_confirmation"
            type="{{ isPasswordVisible ? 'text' : 'password' }}"
            autocomplete="new-password"
            placeholder="入力してください"
            autocapitalize="off"
            autocorrect="off"
          />
          <button
            spenLink
            spenSuffix
            type="button"
            class="is-password-visible"
            (click)="togglePasswordVisibility()"
          >
            {{ isPasswordVisible ? '非表示' : '表示' }}
          </button>
          <!-- 確認用が入力された状態で一致していない場合にエラーを表示 -->
          <spen-form-error
            *ngIf="
              form.errors?.confirmationMatch &&
              (newPasswordConfirmation?.dirty ||
                newPasswordConfirmation?.touched)
            "
            >確認用パスワードが一致していません。</spen-form-error
          >
          <spen-form-error
            *ngIf="
              formErrors?.new_password_confirmation as newPasswordConfirmationError
            "
            >{{ newPasswordConfirmationError }}</spen-form-error
          >
        </spen-form-field>
      </div>
    </section>

    <div class="form-actions">
      <button
        color="primary"
        spenButton
        class="action-button"
        [disabled]="form.invalid || submitInProgress"
      >
        設定する
      </button>
    </div>
  </form>
</main>

<app-footer></app-footer>
