import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseError } from '@angular/fire/app';

import { LoginService } from '../../shared/api/login.service';
import {
  FormErrors,
  KaisatsuResponseError,
} from '../../shared/api/response.error';
import { GlobalMessageService } from '../../shared/global-message.service';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  handleFirebaseError,
  SsoAuthService,
} from '../../shared/sso-auth.service';
import { BackurlService } from '../../shared/backurl.service';
import { kaisatsuHandleError } from '../../shared/kaisatsu-error-handler';
import { Title } from '@angular/platform-browser';
import { LoginChallengeService } from '../../shared/login-challenge.service';
import { isThirdPartyLoginUrl } from '../../shared/is-third-party-login-url';
import { LoginBenesseComponent } from './login-benesse/login-benesse.component';
import { LoginIdentifierFormComponent } from './login-identifier-form/login-identifier-form.component';
import { LoginSsoComponent } from './login-sso/login-sso.component';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../../shared/header/header.component';
import { FooterComponent } from '../../shared/footer/footer.component';
import { GlobalMessageComponent } from '../../shared/global-message/global-message.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    FooterComponent,
    GlobalMessageComponent,
    LoginBenesseComponent,
    LoginIdentifierFormComponent,
    LoginSsoComponent,
  ],
  selector: 'app-login-identifier',
  templateUrl: './login-identifier.component.html',
  styleUrls: ['./login-identifier.component.scss'],
})
export class LoginIdentifierComponent implements OnInit, OnDestroy {
  formErrors: FormErrors | null = null;

  private onDestroy$ = new Subject();

  // TODO: エラーメッセージ本文はリテイク予定
  loginErrorMessages: { [key: string]: string } = {
    school_not_found:
      '貴校はご利用対象外か、もしくはご利用開始前のためこちらのページを表示することができません。ご利用開始にあたっては、下記のClassi社窓口までお問い合わせください。',
    user_not_linked:
      'あなたの情報が登録されていません。先生にお問い合わせ下さい。',
    user_disabled: '利用停止中です。',
    license_not_found:
      'ログインに失敗しました。先生に「利用サービス設定」をご確認ください。',
    bhso_login_failed: '認証キーが不正です。',
  };

  renderPage = false;
  submitInProgress = false;
  isThirdPartyLogin = false;

  constructor(
    private readonly router: Router,
    private readonly loginService: LoginService,
    private readonly backurlService: BackurlService,

    private readonly ssoAuthService: SsoAuthService,
    private readonly globalMessageService: GlobalMessageService,
    private readonly route: ActivatedRoute,
    private readonly loginChallengeService: LoginChallengeService,
    private titleService: Title
  ) {}

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle(this.route.snapshot.data.title);
    // iOS16.1にてsignInWithRedirect後のgetRedirectResultにバグがあり機能していないので、一旦signInWithPopupで実装する
    // ので、以下の処理はコメントアウトしておく

    // SSOログインによるリダイレクトかを検証
    // try {
    //   await this.ssoAuthService.login();
    // } catch (e: FirebaseError | KaisatsuResponseError | unknown) {
    //   if (e instanceof FirebaseError) {
    //     handleFirebaseError(e);
    //   } else if (e instanceof KaisatsuResponseError) {
    //     // グローバルに表示するエラーメッセージをここで保存
    //     this.globalMessageService.setErrorMessage(e.messages);
    //   } else {
    //     kaisatsuErrorHandler(e, this.globalMessageService);
    //   }
    // }

    // エラーメッセージは毎回リセットする
    this.formErrors = null;
    // backurlをリセットする
    this.backurlService.delete();
    // backurlパラメータがある場合は保存する
    const backurl = this.route.snapshot.queryParamMap.get('backurl');
    if (backurl) {
      this.backurlService.save(backurl);
      // backurlが連携サービスログインURLの場合はSSOログインボタンを表示しない
      if (isThirdPartyLoginUrl(backurl)) {
        this.isThirdPartyLogin = true;
      }
    }

    // logout=trueの場合はログアウトメッセージを表示
    const logout = this.route.snapshot.queryParamMap.get('logout');
    if (logout) {
      this.globalMessageService.setErrorMessage({
        general: 'ログアウトしました。',
      });
      // ?logout=trueはアドレスバーから削除する
      history.replaceState('', '', new URL(window.location.href).pathname);
    }

    // errorパラメータがある場合はそれに合わせたエラーメッセージを表示する
    const errorCode = this.route.snapshot.queryParamMap.get('error');
    if (errorCode) {
      if (this.loginErrorMessages[errorCode]) {
        this.globalMessageService.setErrorMessage({
          general: this.loginErrorMessages[errorCode],
          displayInquiry: errorCode === 'school_not_found',
        });
      }
      // errorパラメータはアドレスバーから削除する
      history.replaceState('', '', new URL(window.location.href).pathname);
    }
    this.renderPage = true;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.globalMessageService.delete();
  }

  async getLogin(params: {
    username: string;
    password: string;
  }): Promise<void> {
    this.submitInProgress = true;
    try {
      const loginMethods = await this.loginService.getLoginMethods(
        params.username
      );

      this.loginChallengeService.setInformation({
        ...params,
        loginMethods,
      });

      this.router.navigate(['/login/challenge'], {
        queryParamsHandling: 'preserve',
      });
    } catch (e: KaisatsuResponseError | unknown) {
      if (e instanceof KaisatsuResponseError) {
        // グローバルに表示するエラーメッセージをここで保存
        this.globalMessageService.setErrorMessage(e.messages);
        // フォームに関するエラーがある場合はフォームに渡す
        if (e.messages?.form) {
          this.formErrors = e.messages.form;
        }
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
    }
    this.submitInProgress = false;
  }
}
