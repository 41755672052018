/**
 * @see https://github.com/classi/angular-devkit/blob/main/projects/ngrx-extensions/README.md#store-createfeatureslice
 */

import { createFeatureSlice, PayloadAction } from '@classi/ngrx-extensions';
import { HintLink } from '../api/response.error';

export type GlobalMessage = {
  status: string;
  message: GlobalMessageElement;
};

export type GlobalMessageElement = {
  general: string;
  hint_link?: HintLink;
  displayInquiry?: boolean;
};

type State = {
  globalMessage: GlobalMessage | null;
};

const initialState: State = {
  globalMessage: null,
};

export default createFeatureSlice({
  name: 'globalMessage',
  initialState,
  reducers: {
    setMessage: (
      current: State,
      action: PayloadAction<{ globalMessage: GlobalMessage }>
    ) => {
      return {
        globalMessage: {
          status: action.payload.globalMessage.status,
          message: action.payload.globalMessage.message,
        },
      };
    },
    reset: () => ({ globalMessage: null }),
  },
});
