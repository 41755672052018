import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import {
  SpenButtonModule,
  SpenDialogComponent,
  SpenDialogModule,
  SpenFlashMessageModule,
  SpenFormFieldModule,
  SpenInputModule,
  SpenLabelModule,
  SpenLinkModule,
} from '@classi/spen';
import { MyService } from '../api/my.service';
import { FormErrors, KaisatsuResponseError } from '../api/response.error';
import { GlobalMessageService } from '../global-message.service';
import { kaisatsuHandleError } from '../kaisatsu-error-handler';

@Component({
  standalone: true,
  selector: 'app-confirm-password-dialog',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SpenDialogModule,
    SpenFormFieldModule,
    SpenInputModule,
    SpenLinkModule,
    SpenButtonModule,
    SpenLabelModule,
    SpenFlashMessageModule,
  ],
  templateUrl: './confirm-password-dialog.component.html',
  styleUrls: ['./confirm-password-dialog.component.scss'],
})
export class ConfirmPasswordDialogComponent {
  @Input()
  title: string = '';

  @Output()
  confirmed = new EventEmitter();
  readonly form = this.fb.group({
    password: this.fb.control('', {
      validators: [Validators.required],
    }),
  });
  isPasswordVisible = false;
  submitInProgress = false;
  errorMessage = '';

  @ViewChild('confirmPasswordDialog', { static: true })
  confirmPasswordDialog!: SpenDialogComponent;

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  formErrors: FormErrors | null = null;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly myService: MyService,
    private readonly globalMessageService: GlobalMessageService
  ) {}

  open(): void {
    this.confirmPasswordDialog.open();
  }

  async onSubmit(): Promise<void> {
    this.submitInProgress = true;
    this.globalMessageService.delete();
    try {
      await this.myService.getSudo(this.form.value);
      this.confirmed.emit();
      this.confirmPasswordDialog.close();
      this.form.reset();
    } catch (e: KaisatsuResponseError | unknown) {
      if (e instanceof KaisatsuResponseError) {
        this.errorMessage = e.messages.general;
        // フォームに関するエラーがある場合はフォームに渡す
        if (e.messages?.form) {
          this.formErrors = e.messages.form;
        }
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
    }
    this.submitInProgress = false;
  }
}
