import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MyService } from '../../../shared/api/my.service';
import {
  FormErrors,
  KaisatsuResponseError,
} from '../../../shared/api/response.error';
import { GlobalMessageService } from '../../../shared/global-message.service';
import { ConfirmPasswordDialogComponent } from '../../../shared/confirm-password-dialog/confirm-password-dialog.component';
import { mustBeModified } from '../../../shared/validators/must-be-modified';
import { kaisatsuHandleError } from '../../../shared/kaisatsu-error-handler';

@Component({
  selector: 'app-username-setting-form',
  templateUrl: './username-setting-form.component.html',
  styleUrls: ['./username-setting-form.component.scss'],
})
export class UsernameSettingFormComponent implements OnInit {
  @Input() currentUsername!: string;

  @ViewChild(ConfirmPasswordDialogComponent, { static: true })
  confirmPasswordDialog!: ConfirmPasswordDialogComponent;

  public readonly form = this.fb.group({
    new_username: this.fb.control('', {}),
  });

  formErrors: FormErrors | null = null;
  submitInProgress = false;
  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly myService: MyService,
    private readonly globalMessageService: GlobalMessageService
  ) {}

  ngOnInit(): void {
    this.setUpForm(this.currentUsername);
  }

  setUpForm(initialValue: string): void {
    this.form.setValue({ new_username: initialValue });
    this.form.controls.new_username.setValidators([
      Validators.required,
      mustBeModified(initialValue),
    ]);
    this.form.controls.new_username.updateValueAndValidity();
  }

  async onSubmit(): Promise<void> {
    this.submitInProgress = true;
    this.globalMessageService.delete();
    try {
      this.formErrors = null;
      await this.myService.putUsername(this.form.value);

      this.globalMessageService.setSuccessMessage('Classi IDを変更しました。');
      // 更新した値でformを再度セットアップする
      this.setUpForm(this.form.value.new_username);
      // 上部に成功メッセージが表示されるので一番上にスクロール
      window.scrollTo(0, 0);
    } catch (e: KaisatsuResponseError | unknown) {
      if (e instanceof KaisatsuResponseError) {
        if (e.messages.sudo_required) {
          // 現在のパスワード入力を要求する
          this.confirmPasswordDialog.open();
        } else {
          // グローバルに表示するエラーメッセージをここで保存
          this.globalMessageService.setErrorMessage(e.messages);
        }
        // フォームに関するエラーがある場合はフォームに渡す
        if (e.messages?.form) {
          this.formErrors = e.messages.form;
        }
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
    }
    this.submitInProgress = false;
  }

  retryOnSubmit(): void {
    this.onSubmit();
  }
}
