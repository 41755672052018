import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import PasswordResetSlice from './store/password-reset.slice';

@Injectable({
  providedIn: 'root',
})
export class PasswordResetInfoService {
  constructor(private readonly store: Store<unknown>) {}
  set(username: string, address?: string): void {
    this.store.dispatch(
      PasswordResetSlice.actions.setInformation({
        username: username,
        address: address,
      })
    );
  }
  get(): Observable<{ username: string; address: string }> {
    return PasswordResetSlice.select(this.store, (state: any) => state);
  }
}
