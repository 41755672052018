import { Pipe, PipeTransform } from '@angular/core';

const accountTypeToName = {
  teacher: '先生',
  student: '生徒',
  parent: '保護者',
};

@Pipe({
  standalone: true,
  name: 'accountTypeName',
})
export class AccountTypeNamePipe implements PipeTransform {
  transform(value: 'teacher' | 'student' | 'parent'): string {
    return accountTypeToName[value];
  }
}
