import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { SpenDialogComponent } from '@classi/spen';
import { KaisatsuResponseError } from '../../../../shared/api/response.error';
import { SchoolAdminService } from '../../../../shared/api/school-admin.service';
import { SsoUser } from '../../../../shared/api/types';
import { GlobalMessageService } from '../../../../shared/global-message.service';
import { kaisatsuHandleError } from '../../../../shared/kaisatsu-error-handler';

@Component({
  selector: 'app-sso-users-account-edit-dialog',
  templateUrl: './sso-users-account-edit-dialog.component.html',
  styleUrls: ['./sso-users-account-edit-dialog.component.scss'],
})
export class SsoUsersAccountEditDialogComponent {
  @ViewChild('accountEditDialog', { static: true })
  accountEditDialog!: SpenDialogComponent;

  @Output()
  ssoUserUpdate = new EventEmitter<void>();

  user!: SsoUser;
  linkInProgress = false;
  readonly googleAccountForm = this.fb.group({
    google_account: this.fb.control('', {
      validators: [Validators.required, Validators.email],
    }),
  });

  readonly microsoftAccountForm = this.fb.group({
    microsoft_account: this.fb.control('', {
      validators: [Validators.required, Validators.email],
    }),
  });
  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly schoolAdminService: SchoolAdminService,
    private readonly globalMessageService: GlobalMessageService
  ) {}

  open(user: SsoUser): void {
    if (user.google_account) {
      this.googleAccountForm.setValue({
        google_account: user.google_account,
      });
      this.googleAccountForm.disable();
    } else {
      this.googleAccountForm.enable();
    }

    if (user.microsoft_account) {
      this.microsoftAccountForm.setValue({
        microsoft_account: user.microsoft_account,
      });
      this.microsoftAccountForm.disable();
    } else {
      this.microsoftAccountForm.enable();
    }

    this.user = user;
    this.accountEditDialog.open();
  }

  onAfterClosed(): void {
    this.googleAccountForm.reset();
    this.microsoftAccountForm.reset();
    this.globalMessageService.delete();
  }

  async unlinkGoogleAccount(): Promise<void> {
    const confirm = window.confirm(
      `${this.user.name}のGoogleアカウントの連携を解除します。よろしいですか？`
    );
    if (confirm) {
      try {
        await this.schoolAdminService.deleteSsoUserGoogleAccount(this.user.id);
        this.globalMessageService.setSuccessMessage(
          `${this.user.name}のGoogleアカウントの連携を解除しました。`
        );
        // Formを初期状態にし、ユーザーの更新を親に知らせる
        this.googleAccountForm.reset();
        this.googleAccountForm.enable();
        this.ssoUserUpdate.emit();
      } catch (e: KaisatsuResponseError | unknown) {
        if (e instanceof KaisatsuResponseError) {
          // グローバルに表示するエラーメッセージをここで保存
          this.globalMessageService.setErrorMessage(e.messages);
        } else {
          kaisatsuHandleError(e, this.globalMessageService);
        }
      }
    }
  }

  async unlinkMicrosoftAccount(): Promise<void> {
    const confirm = window.confirm(
      `${this.user.name}のMicrosoftアカウントの連携を解除します。よろしいですか？`
    );
    if (confirm) {
      try {
        await this.schoolAdminService.deleteSsoUserMicrosoftAccount(
          this.user.id
        );
        this.globalMessageService.setSuccessMessage(
          `${this.user.name}のMicrosoftアカウントの連携を解除しました。`
        );
        // Formを初期状態にし、ユーザーの更新を親に知らせる
        this.microsoftAccountForm.reset();
        this.microsoftAccountForm.enable();
        this.ssoUserUpdate.emit();
      } catch (e: KaisatsuResponseError | unknown) {
        if (e instanceof KaisatsuResponseError) {
          // グローバルに表示するエラーメッセージをここで保存
          this.globalMessageService.setErrorMessage(e.messages);
        } else {
          kaisatsuHandleError(e, this.globalMessageService);
        }
      }
    }
  }

  async linkGoogleAccount(): Promise<void> {
    this.linkInProgress = true;
    try {
      await this.schoolAdminService.postSsoUserGoogleAccount(
        this.user.id as string,
        this.googleAccountForm.value
      );
      this.globalMessageService.setSuccessMessage(
        `${this.user.name}のGoogleアカウントの連携を設定しました。`
      );

      this.googleAccountForm.disable();
      this.ssoUserUpdate.emit();
    } catch (e: KaisatsuResponseError | unknown) {
      if (e instanceof KaisatsuResponseError) {
        // グローバルに表示するエラーメッセージをここで保存
        this.globalMessageService.setErrorMessage(e.messages);
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
    }
    this.linkInProgress = false;
  }

  async linkMicrosoftAccount(): Promise<void> {
    this.linkInProgress = true;
    try {
      await this.schoolAdminService.postSsoUserMicrosoftAccount(
        this.user.id as string,
        this.microsoftAccountForm.value
      );
      this.globalMessageService.setSuccessMessage(
        `${this.user.name}のMicrosoftアカウントの連携を設定しました。`
      );
      this.microsoftAccountForm.disable();
      this.ssoUserUpdate.emit();
    } catch (e: KaisatsuResponseError | unknown) {
      if (e instanceof KaisatsuResponseError) {
        // グローバルに表示するエラーメッセージをここで保存
        this.globalMessageService.setErrorMessage(e.messages);
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
    }
    this.linkInProgress = false;
  }
}
