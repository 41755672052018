import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function confirmationMatchValidator(
  name: string,
  confirmationName: string
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const form = control.get(name)!;
    const confirmationForm = control.get(confirmationName)!;

    return form.value === confirmationForm.value
      ? null
      : { confirmationMatch: true };
  };
}
