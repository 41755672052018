<form (submit)="onSubmit()" [formGroup]="form">
  <div class="password-form">
    <spen-form-field>
      <input
        #passwordInput
        spenInput
        name="password"
        type="{{ isPasswordVisible ? 'text' : 'password' }}"
        autocomplete="current-password"
        formControlName="password"
        placeholder="入力してください"
        autocapitalize="off"
        autocorrect="off"
        required
      />
      <button
        spenLink
        spenSuffix
        type="button"
        class="is-password-visible"
        (click)="togglePasswordVisibility()"
      >
        {{ isPasswordVisible ? '非表示' : '表示' }}
      </button>
      <spen-form-error *ngIf="formErrors?.password as passwordError">{{
        passwordError
      }}</spen-form-error>
    </spen-form-field>
  </div>

  <div class="id-omit-check">
    <spen-checkbox formControlName="saveId"
      >次回からIDの入力を省略する</spen-checkbox
    >
    <p>※共有端末の場合はチェックしないでください</p>
  </div>

  <div class="forget-password">
    <a spenLink routerLink="/password_reset/select_method"
      >パスワードをお忘れの場合</a
    >
  </div>

  <!-- reCAPTCHA -->
  <div class="recaptcha" *ngIf="loginMethod.recaptcha">
    <div #grecaptchaContainer></div>
  </div>

  <button
    color="primary"
    spenButton
    [additionalClass]="'is-full'"
    [disabled]="form.invalid || submitInProgress"
  >
    {{ submitInProgress ? 'ログイン中' : 'ログインする' }}
  </button>
</form>
