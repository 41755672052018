import { Component, ChangeDetectionStrategy } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SpenModule } from '@classi/spen';

@Component({
  standalone: true,
  imports: [SpenModule],
  selector: 'app-login-benesse',
  templateUrl: './login-benesse.component.html',
  styleUrls: ['./login-benesse.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginBenesseComponent {
  benesseLoginUrl = environment.benesseLoginUrl;
  constructor() {}
}
