/**
 * @see https://github.com/classi/classi_auth/blob/4e77ae60c5473799f7bcd4059ad8139635ea9832/config/settings.yml#L71
 */

import { environment } from '../../../environments/environment';

export function isPermittedBackurl(backurl: string): boolean {
  return isNativeAppsUrl(backurl) || isPermittedDomain(backurl);
}

function isNativeAppsUrl(backurl: string): boolean {
  return nativeAppsUrls.includes(backurl);
}

function isPermittedDomain(backurl: string): boolean {
  try {
    // backurlが改竄され正しくparseできない場合はfalseで返す
    const hostname = new URL(backurl).hostname;
    const permitDomain = permitDomains.find((domain) => {
      // ドメインの前は .（サブドメイン）であること
      // 末尾一致すること　（ classi.jp.attack.com を弾くため）
      const regex = new RegExp('(\\b\\.{1})+' + `${domain}` + '$');
      return regex.test(hostname);
    });
    return permitDomain ? true : false;
  } catch {
    return false;
  }
}

const nativeAppsUrls: string[] = [
  environment.studentAppsUrl,
  environment.movieAppsUrl,
  environment.teacherAppsUrl,
  environment.homeAppsUrls,
];

const permitDomains: string[] = [
  // Classiで使用しているドメイン
  'classi.jp',
  'classi.education',
  // SSOで使用しているドメイン
  'benesse.ne.jp',
  // 連携サービスで使用しているドメイン
  'classinote.com',
  'cnnee.jp',
  'monaca.mobi',
  'englishcentral.com',
  'qaenglishcentral.com',
  'splyza.com',
  'kids-sign.jp',
  'school-sign.jp',
  'netlearning.co.jp',
  'myet.com',
  'myetspeaking.com',
  'english-pass.net',
  'monaca.education',
  'school-fal.com',
  'find-activelearning.com',
  // TODO: 正しい制御になっていないので修正する
  'page.link',
];
