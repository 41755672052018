<spen-heading [level]="3">他のIDでログイン</spen-heading>
<button
  spenButton
  [additionalClass]="'is-full'"
  (click)="loginWithGoogle()"
  [disabled]="googleLoading || microsoftLoading"
>
  <img src="assets/img/google.svg" /><span>{{
    googleLoading ? 'ログイン中' : 'Googleのアカウント'
  }}</span>
</button>
<button
  spenButton
  [additionalClass]="'is-full'"
  (click)="loginWithMicrosoft()"
  [disabled]="googleLoading || microsoftLoading"
>
  <img src="assets/img/microsoft.svg" /><span>{{
    microsoftLoading ? 'ログイン中' : 'Microsoftのアカウント'
  }}</span>
</button>
<p class="notice">
  ※保護者の方はご利用いただけません。<br />
  ご利用には管理者先生による事前設定が必要です。
</p>
