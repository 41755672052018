<footer *ngIf="!noFooterRequired()">
  <!-- ログイン前に表示するfooter -->
  <div *ngIf="!user" class="before-login-footer">
    <ul class="footer-link-list" *ngIf="!hiddenLink">
      <li class="footer-link">
        <a
          spenLink
          href="https://support.classi.jp/hc/ja"
          target="_blank"
          rel="noopener"
          >ヘルプページ</a
        >
      </li>
      <li class="footer-link">
        <a
          spenLink
          href="https://status.classi.jp/"
          target="_blank"
          rel="noopener"
          >サービス稼働情報</a
        >
      </li>
    </ul>
    <div class="copyright">Copyright © Classi Corp. All Rights Reserved.</div>
  </div>

  <!-- ログイン後にuserを取得できる状態の時に表示するfooter -->
  <div *ngIf="user">
    <spen-footer [userTypeId]="(user | currentUser).userTypeId"></spen-footer>
  </div>
</footer>
