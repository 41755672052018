import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ValueFromAppService } from '../shared/value-from-app.service';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly valueFromAppService: ValueFromAppService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          const iframe = this.route.snapshot.queryParamMap.get('iframe');
          switch (error.status) {
            case 401:
              if (!error.error?.errors?.sudo_required) {
                const appFallbackUrl =
                  this.valueFromAppService.getFallbackUrl();
                // fallbackUrlが設定されていればそのURLに、なければ/login/identifierに戻す
                if (appFallbackUrl) {
                  window.location.href = appFallbackUrl;
                } else {
                  this.router.navigateByUrl('/login/identifier');
                }
              }
              break;
            case 500:
              if (iframe) {
                this.router.navigate(['/500'], {
                  queryParams: { iframe: true },
                  skipLocationChange: true,
                });
              } else {
                this.router.navigateByUrl('/500', {
                  skipLocationChange: true,
                });
              }
              break;
            case 503:
              if (iframe) {
                this.router.navigate(['/503'], {
                  queryParams: { iframe: true },
                });
              } else {
                this.router.navigateByUrl('/503');
              }
              break;
            case 0:
              // ログインフローにいる場合はログイントップに戻す
              if (window.location.pathname.startsWith('/login/')) {
                this.router.navigateByUrl('/login/identifier');
              }
              break;
          }
        }
        return throwError(error);
      })
    );
  }
}
