<app-sso-users-search-field
  *ngIf="searchOptions"
  [accountType]="'teacher'"
  [schoolYears]="searchOptions.school_years"
  [schoolStages]="searchOptions.stages"
  [grades]="searchOptions.grades"
  [currentFilter]="currentFilter"
  [submitInProgress]="searchInProgress"
  (searchSubmit)="searchTeachers($event)"
  (currentFilterUpdate)="currentFilterUpdate($event)"
></app-sso-users-search-field>

<app-sso-users-list
  [accountType]="'teacher'"
  [usersList]="teachersList"
  (pageChange)="getPageTeachers($event)"
  (ssoUserUpdate)="refreshTeachers()"
></app-sso-users-list>
