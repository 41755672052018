import { HttpErrorResponse } from '@angular/common/http';
import { GlobalMessageElement } from '../store/global-message.slice';

export type FormErrors = {
  [key: string]: string;
};

export type HintLink = {
  url: string;
  title: string;
  label: string;
};

type ErrorMessages = GlobalMessageElement & {
  form?: FormErrors;
  sudo_required?: boolean;
  retryable?: boolean;
};

export class KaisatsuResponseError {
  status: number;
  messages: ErrorMessages;
  constructor(status: number, messages: ErrorMessages) {
    this.status = status;
    this.messages = messages;
  }
}

export function handleErrorResponse(
  err: HttpErrorResponse
): KaisatsuResponseError | any {
  const responseError = err.error?.errors;
  if (responseError) {
    throw new KaisatsuResponseError(err.status, responseError);
  }
  throw err;
}
