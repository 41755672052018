import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import globalMessageSlice, {
  GlobalMessage,
  GlobalMessageElement,
} from './store/global-message.slice';

@Injectable({
  providedIn: 'root',
})
export class GlobalMessageService {
  constructor(private readonly store: Store<unknown>) {}

  get(): Observable<GlobalMessage | null> {
    return globalMessageSlice.select(
      this.store,
      (state) => state.globalMessage
    );
  }

  setErrorMessage(messages: GlobalMessageElement): void {
    this.store.dispatch(
      globalMessageSlice.actions.setMessage({
        globalMessage: {
          status: 'error',
          message: messages,
        },
      })
    );
  }
  setSuccessMessage(message: string): void {
    this.store.dispatch(
      globalMessageSlice.actions.setMessage({
        globalMessage: {
          status: 'success',
          message: { general: message },
        },
      })
    );
  }

  delete(): void {
    this.store.dispatch(globalMessageSlice.actions.reset());
  }
}
