import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../shared/api/login.service';
import { KaisatsuResponseError } from '../../shared/api/response.error';
import { BackurlService } from '../../shared/backurl.service';
import { GlobalMessageService } from '../../shared/global-message.service';
import { kaisatsuHandleError } from '../../shared/kaisatsu-error-handler';

export type LoginContinueResponse = {
  account_select_required: boolean;
  profile_confirmation_required: boolean;
  tos_required: boolean;
  password_change_required: boolean;
  benesse_account_invitation_code_required: boolean;
  email_registration_guide_required: boolean;
};

@Component({
  selector: 'app-login-continue',
  templateUrl: './login-continue.component.html',
  styleUrls: ['./login-continue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginContinueComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly backurlService: BackurlService,
    private readonly loginService: LoginService,
    private readonly globalMessageService: GlobalMessageService,
    private readonly titleService: Title
  ) {}

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle(this.route.snapshot.data.title);
    this.globalMessageService.delete();

    // backurlパラメータがある場合は保存する
    const backurl = this.route.snapshot.queryParamMap.get('backurl');
    if (backurl) {
      this.backurlService.save(backurl);
    }
    // responseによって次の遷移先が決定する
    try {
      const response = await this.loginService.getLoginContinue();
      if (response.account_select_required) {
        this.router.navigateByUrl('/login/choose_account');
      } else if (response.benesse_account_invitation_code_required) {
        this.router.navigateByUrl('/login/benesse_invitation_code');
      } else if (response.profile_confirmation_required) {
        this.router.navigateByUrl('/login/profile_confirmation');
      } else if (response.tos_required) {
        this.router.navigateByUrl('/login/tos_agreement');
      } else if (response.email_registration_guide_required) {
        this.router.navigateByUrl('/login/email_registration');
      } else if (response.password_change_required) {
        this.router.navigateByUrl('/login/initial_setting');
      } else {
        this.router.navigateByUrl('/login/complete');
      }
    } catch (e: KaisatsuResponseError | unknown) {
      if (e instanceof KaisatsuResponseError) {
        // グローバルに表示するエラーメッセージをここで保存
        this.globalMessageService.setErrorMessage(e.messages);
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
    }
  }
}
