import { HttpErrorResponse } from '@angular/common/http';
import { AjaxError, AjaxTimeoutError } from 'rxjs/ajax';
import { GlobalMessageService } from './global-message.service';
import { ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

export class KaisatsuErrorHandler implements ErrorHandler {
  // try catch以外で発生したエラーを処理する
  handleError(err: any): void {
    // https://classi.sentry.io/issues/3101698231/?project=5986400
    if (
      /Connection to Indexed Database server lost. Refresh the page to try again/.test(
        err.message
      )
    ) {
      window.alert('ページの再読み込みを行います。');
      window.location.reload();

      // アプリケーションがバックグラウンドに移行する/非アクティブになった際に発生するエラー
      // https://classi.sentry.io/issues/3181701782/?project=5986400
    } else if (/Transaction timed out due to inactivity/.test(err.message)) {
      window.alert('ページの再読み込みを行います。');
      window.location.reload();

      // ChunkLoadErrorの対応
    } else if (/Loading chunk [\d]+ failed/.test(err.message)) {
      window.alert(
        '読み込み中にエラーが発生しました。ページの再読み込みを行います。'
      );
      window.location.reload();
    } else {
      console.log(`name: ${err.name}, message: ${err.message}`);
      Sentry.captureException(err);
    }
  }
}

/**
 *
 * @param err
 * @param globalMessageService(optional)
 * グローバルメッセージとして表示できる場合にはglobalMessageServiceを渡す
 *
 */
export function kaisatsuHandleError(
  err: any,
  globalMessageService?: GlobalMessageService
): void {
  const offlineMessage = window.location.pathname.startsWith('/login/')
    ? 'サーバーに接続できませんでした。通信環境のご確認、または再度時間を置いてお試しください。ログイントップ画面に戻ります。'
    : 'サーバーに接続できませんでした。通信環境のご確認、または再度時間を置いてお試しください。';
  if (err instanceof HttpErrorResponse) {
    switch (err.status) {
      case 0:
        window.alert(offlineMessage);
        break;
      // CSRFトークンの検証に失敗した場合サーバから返ってくるエラー
      // Cookieがブロックされている場合にはCSRFトークンがセットできずにこのエラーになる
      case 422:
        if (globalMessageService) {
          globalMessageService?.setErrorMessage({
            general:
              'リクエストに失敗しました。Cookieが有効になっているか、または端末の日付、時刻が正しく設定されているかご確認ください。',
          });
        } else {
          window.alert(
            'リクエストに失敗しました。Cookieの設定が許可されているかをご確認ください。'
          );
        }
    }
  } else if (err instanceof AjaxError || AjaxTimeoutError) {
    switch (err.status) {
      case 0:
        if (globalMessageService) {
          globalMessageService?.setErrorMessage({
            general: offlineMessage,
          });
        } else {
          window.alert(offlineMessage);
        }
        break;
      case 503:
        // メンテナンス用のレスポンスが帰ってきた場合にはglobalMessageにセットする。
        globalMessageService?.setErrorMessage(err.response.errors);
    }
  } else if (err instanceof AjaxTimeoutError) {
    window.alert(offlineMessage);
  } else {
    throw err;
  }
}
