/**
 * @see https://github.com/classi/angular-devkit/blob/main/projects/ngrx-extensions/README.md#store-createfeatureslice
 */

import { createFeatureSlice } from '@classi/ngrx-extensions';
import { PayloadAction } from '@reduxjs/toolkit';
import { LoginMethod } from '../api/types';

export type State = {
  username: string | null;
  password: string | null;
  loginMethods: LoginMethod[];
};

const initialState: State = {
  username: null,
  password: null,
  loginMethods: [],
};

export default createFeatureSlice({
  name: 'loginChallenge',
  initialState,
  reducers: {
    setInformation: (
      current: State,
      action: PayloadAction<{
        username: string;
        password: string;
        loginMethods: LoginMethod[];
      }>
    ) => {
      return {
        username: action.payload.username,
        password: action.payload.password,
        loginMethods: action.payload.loginMethods,
      };
    },
  },
});
