import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { SpenSelectOption, SpenSelectValue } from '@classi/spen';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  Classroom,
  Grade,
  SchoolStage,
  SsoUsersSearchParams,
  SearchSchoolYear,
  CurrentFilterSet,
} from '../../../shared/api/types';
import { changeUsersSearchParams } from '../change-users-search-params';

@Component({
  selector: 'app-sso-users-search-field',
  templateUrl: './sso-users-search-field.component.html',
  styleUrls: ['./sso-users-search-field.component.scss'],
})
export class SsoUsersSearchFieldComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input()
  accountType: 'teacher' | 'student' | null = null;
  @Input()
  schoolYears: SearchSchoolYear[] = [];
  @Input()
  schoolStages: SchoolStage[] = [];
  @Input()
  grades: Grade[] = [];
  @Input()
  classrooms: Classroom[] = [];
  @Input()
  currentFilter!: CurrentFilterSet;
  @Input()
  submitInProgress!: boolean;

  @Output()
  currentFilterUpdate = new EventEmitter<Partial<CurrentFilterSet>>();
  @Output()
  searchSubmit = new EventEmitter<SsoUsersSearchParams>();

  constructor(private readonly fb: UntypedFormBuilder) {}

  schoolYearOptions: SpenSelectOption[] = [];
  schoolStageOptions: SpenSelectOption[] = [];
  gradeOptions: SpenSelectOption[] = [];
  classroomOptions: SpenSelectOption[] = [];

  readonly form = this.fb.group({
    attendance_number: this.fb.control(''),
    email: this.fb.control(''),
  });

  private onDestroy$ = new Subject();
  ngOnInit(): void {
    this.buildFormOptions();
    this.buildSelectBoxOptions();
  }

  ngOnChanges(): void {
    this.buildSelectBoxOptions();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  get isGradeEnabled(): boolean {
    return this.gradeOptions.length > 0;
  }

  get isClassroomEnabled(): boolean {
    return this.classroomOptions.length > 0;
  }

  get isAttendanceNumberEnabled(): boolean {
    return this.currentFilter.classroom_id ? true : false;
  }

  changeSchoolYear(schoolYear: SpenSelectValue) {
    this.currentFilterUpdate.emit({
      school_year: schoolYear,
      stage_id: null,
      grade_id: null,
      classroom_id: null,
      attendance_number: null,
    });

    this.form.controls.attendance_number.reset();
  }

  changeStage(stageId: SpenSelectValue) {
    this.currentFilterUpdate.emit({
      stage_id: stageId,
      grade_id: null,
      classroom_id: null,
      attendance_number: null,
    });

    this.form.controls.attendance_number.reset();
  }

  changeGrade(gradeId: SpenSelectValue) {
    this.currentFilterUpdate.emit({
      grade_id: gradeId,
      classroom_id: null,
      attendance_number: null,
    });

    this.form.controls.attendance_number.reset();
  }

  changeClassroom(classroomId: SpenSelectValue) {
    this.currentFilterUpdate.emit({
      classroom_id: classroomId,
      attendance_number: null,
    });

    this.form.controls.attendance_number.reset();
  }

  onSubmit(): void {
    this.searchSubmit.emit(changeUsersSearchParams(this.currentFilter));
  }

  private buildSelectBoxOptions() {
    this.buildSchoolYearOptions();
    this.buildSchoolStageOptions();
    this.buildGradeOptions();
    this.buildClassroomOptions();
  }

  private buildSchoolYearOptions() {
    this.schoolYearOptions = this.schoolYears.map((schoolYear) => ({
      text: `${schoolYear.year}`,
      value: schoolYear.year,
    }));
  }

  private buildSchoolStageOptions() {
    this.schoolStageOptions = this.buildFilterItemList(this.schoolStages);
  }

  private buildGradeOptions() {
    if (this.currentFilter.stage_id) {
      const grades = this.grades.filter(
        (grade) => grade.stage_id === this.currentFilter.stage_id
      );
      this.gradeOptions = this.buildFilterItemList(grades);
    } else {
      this.gradeOptions = [];
    }
  }

  private buildClassroomOptions() {
    if (this.currentFilter.grade_id) {
      const classrooms = this.classrooms.filter(
        (classroom) => classroom.grade_id === this.currentFilter.grade_id
      );
      this.classroomOptions = this.buildFilterItemList(classrooms);
    } else {
      this.classroomOptions = [];
    }
  }

  private buildFormOptions() {
    this.form.setValue({
      attendance_number: this.currentFilter.attendance_number,
      email: this.currentFilter.email,
    });

    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        this.currentFilterUpdate.emit(value);
      });
  }

  private buildFilterItemList<T extends { id: number; name: string }>(
    entities: T[]
  ): SpenSelectOption[] {
    if (entities.length === 0) {
      return [];
    }
    const items = entities.map((g) => ({ text: g.name, value: g.id }));
    return [{ text: 'すべて', value: null }, ...items];
  }
}
