import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginChallengeComponent } from './pages/login-challenge/login-challenge.component';
import { LoginCompleteComponent } from './pages/login-complete/login-complete.component';
import { LoginIdentifierComponent } from './pages/login-identifier/login-identifier.component';
import { LoginInitialSettingComponent } from './pages/login-initial-setting/login-initial-setting.component';
import { LoginTosAgreementComponent } from './pages/login-tos-agreement/login-tos-agreement.component';
import { LoginSettingComponent } from './pages/login-setting/login-setting.component';
import { SchoolSsoComponent } from './pages/school-sso/school-sso.component';
import { LoginChooseAccountComponent } from './pages/login-choose-account/login-choose-account.component';
import { SchoolSsoUsersSettingComponent } from './pages/school-sso-users-setting/school-sso-users-setting.component';
import { SchoolManabiComponent } from './pages/school-manabi/school-manabi.component';
import { LoginContinueComponent } from './pages/login-continue/login-continue.component';
import { LoginProfileConfirmationComponent } from './pages/login-profile-confirmation/login-profile-confirmation.component';
import { LoginEmailRegistrationComponent } from './pages/login-email-registration/login-email-registration.component';
import { BcLoginManabiBeginComponent } from './pages/bc-login-manabi-begin/bc-login-manabi-begin.component';
import { BcLoginManabiChallengeComponent } from './pages/bc-login-manabi-challenge/bc-login-manabi-challenge.component';
import { BcLoginManabiCompleteComponent } from './pages/bc-login-manabi-complete/bc-login-manabi-complete.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login/identifier',
    pathMatch: 'full',
    data: { title: 'Classi - ログイン' },
  },
  {
    path: 'login',
    children: [
      {
        path: 'identifier',
        component: LoginIdentifierComponent,
        data: { title: 'Classi - ログイン', description: 'ログインページ' },
      },
      {
        path: 'challenge',
        component: LoginChallengeComponent,
        data: { title: 'Classi - ログイン', description: 'パスワード入力画面' },
      },
      {
        path: 'continue',
        component: LoginContinueComponent,
        data: { title: 'Classi - ログイン', description: 'ログイン進行中画面' },
      },
      {
        path: 'tos_agreement',
        component: LoginTosAgreementComponent,
        data: {
          title: 'Classi - 利用規約同意',
          description: '利用規約同意画面',
        },
      },
      {
        path: 'complete',
        component: LoginCompleteComponent,
        data: { title: 'Classi - ログイン', description: 'ログイン完了画面' },
      },
      {
        path: 'initial_setting',
        component: LoginInitialSettingComponent,
        data: {
          title: 'Classi - アカウント初期設定',
          description: '初回ログイン時の設定画面',
        },
      },
      {
        path: 'email_registration',
        loadComponent: () =>
          import(
            './pages/login-email-registration/login-email-registration.component'
          ).then((mod) => mod.LoginEmailRegistrationComponent),
        data: {
          title: 'Classi - メールアドレスの登録',
          description: 'メールアドレスの登録画面',
        },
      },
      {
        path: 'setting',
        component: LoginSettingComponent,
        data: {
          title: 'Classi - アカウント設定',
          description: 'アカウント設定画面',
        },
      },
      {
        path: 'email_setting',
        data: {
          title: 'Classi - メールアドレス設定',
          description: 'メールアドレスの登録・解除画面',
        },
        loadComponent: () =>
          import(
            './pages/login-email-setting/login-email-setting.component'
          ).then((mod) => mod.LoginEmailSettingComponent),
      },
      {
        path: 'choose_account',
        component: LoginChooseAccountComponent,
        data: {
          title: 'Classi - アカウント選択',
          description: 'アカウント選択画面',
        },
      },
      {
        path: 'profile_confirmation',
        component: LoginProfileConfirmationComponent,
        data: {
          title: 'Classi - 本人確認',
          description: '本人確認画面',
        },
      },
      {
        path: 'benesse_invitation_code',
        data: {
          title: 'Classi - 招待コード入力画面',
          description: 'ベネッセログイン後に招待コードを入力させる画面',
        },
        loadComponent: () =>
          import(
            './pages/login-benesse-invitation-code/login-benesse-invitation-code.component'
          ).then((mod) => mod.LoginBenesseInvitationCodeComponent),
      },
    ],
  },
  {
    path: 'bc_login',
    children: [
      {
        path: 'manabi',
        redirectTo: '/bc_login/manabi/begin',
        pathMatch: 'full',
      },
      {
        path: 'manabi',
        children: [
          {
            path: 'begin',
            component: BcLoginManabiBeginComponent,
            data: {
              title: 'Classi - マナビジョンログイン',
              description: 'マナビジョンログインページ',
            },
          },
          {
            path: 'challenge',
            component: BcLoginManabiChallengeComponent,
            data: {
              title: 'Classi - マナビジョンログイン',
              description: 'マナビジョンパスワード入力画面',
            },
          },
          {
            path: 'complete',
            component: BcLoginManabiCompleteComponent,
            data: {
              title: 'Classi - マナビジョンログイン',
              description: 'マナビジョンログイン完了画面',
            },
          },
        ],
      },
    ],
  },
  {
    path: 'password_reset',
    loadComponent: () =>
      import('./pages/password-reset/password-reset.component').then(
        (mod) => mod.PasswordResetComponent
      ),
    data: {
      title: 'Classi - パスワード再設定',
      description: 'パスワード再設定画面',
    },
  },
  {
    path: 'password_reset',
    children: [
      {
        path: 'select_method',
        loadComponent: () =>
          import(
            './pages/password-reset-select-method/password-reset-select-method.component'
          ).then((mod) => mod.PasswordResetSelectMethodComponent),
        data: {
          title: 'Classi - パスワード再設定',
          description: 'パスワード再設定方法の選択画面',
        },
      },
      {
        path: 'with_email',
        loadComponent: () =>
          import(
            './pages/password-reset-with-email/password-reset-with-email.component'
          ).then((mod) => mod.PasswordResetWithEmailComponent),
        data: {
          title: 'Classi - パスワード再設定',
          description: 'メールアドレスによるパスワード再設定画面',
        },
      },
    ],
  },
  {
    path: 'school',
    children: [
      {
        path: 'setting',
        data: {
          title: 'Classi - ログインセキュリティ設定',
          description: 'ログインセキュリティ設定画面',
        },
        loadComponent: () =>
          import('./pages/school-setting/school-setting.component').then(
            (mod) => mod.SchoolSettingComponent
          ),
      },
      {
        path: 'sso',
        component: SchoolSsoComponent,
        data: {
          title: 'Classi - Google/Microsoft連携設定',
          description: 'Google/Microsoft連携設定画面',
        },
      },
      {
        path: 'sso',
        children: [
          {
            path: 'users_setting',
            component: SchoolSsoUsersSettingComponent,
            data: {
              title: 'Classi - Google/Microsoft連携設定',
              description: 'Google/Microsoft一括連携設定画面',
            },
          },
        ],
      },
      {
        path: 'manabi',
        component: SchoolManabiComponent,
        data: {
          title: 'Classi - マナビジョン連携',
          description: 'マナビジョン連携画面',
        },
      },
    ],
  },
  {
    path: 'tos',
    data: {
      title: 'Classi - 利用規約',
      description: '利用規約表示画面',
      tosType: 'eula',
    },
    loadComponent: () =>
      import('./pages/tos/tos.component').then((mod) => mod.TosComponent),
  },
  {
    path: 'notice',
    data: {
      title: 'Classi - 注意事項',
      description: '注意事項表示画面',
      tosType: 'notice',
    },
    loadComponent: () =>
      import('./pages/tos/tos.component').then((mod) => mod.TosComponent),
  },
  {
    path: 'R',
    children: [
      {
        path: ':app_name/:action',
        data: {
          title: 'Classi - リダイレクト',
          description: 'アプリへのリダイレクト用画面',
        },
        loadComponent: () =>
          import('./pages/redirect-app/redirect-app.component').then(
            (mod) => mod.RedirectAppComponent
          ),
      },
    ],
  },
  {
    path: 'dummy_after_login',
    data: {
      title: '開発用ログイン完了ページ',
      description: '開発用ログイン完了ページ',
    },
    loadComponent: () =>
      import('./pages/dummy-after-login/dummy-after-login.component').then(
        (mod) => mod.DummyAfterLoginComponent
      ),
  },

  {
    path: '403',
    data: {
      title: 'Classi - 403',
    },
    loadComponent: () =>
      import('./error-pages/forbidden/forbidden.component').then(
        (mod) => mod.ForbiddenComponent
      ),
  },
  {
    path: '500',
    data: {
      title: 'Classi - 500',
    },
    loadComponent: () =>
      import('./error-pages/system-error/system-error.component').then(
        (mod) => mod.SystemErrorComponent
      ),
  },
  {
    path: '503',
    data: {
      title: 'Classi - 503',
    },
    loadComponent: () =>
      import(
        './error-pages/service-unavailable/service-unavailable.component'
      ).then((mod) => mod.ServiceUnavailableComponent),
  },
  {
    path: '**',
    data: {
      title: 'Classi - 404',
    },
    loadComponent: () =>
      import('./error-pages/not-found/not-found.component').then(
        (mod) => mod.NotFoundComponent
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
