import { Component } from '@angular/core';
import { GlobalMessageService } from '../../../shared/global-message.service';
import {
  handleFirebaseError,
  SsoAuthService,
} from '../../../shared/sso-auth.service';
import { ValueFromAppService } from '../../../shared/value-from-app.service';
import { FirebaseError } from '@angular/fire/app';
import { Router } from '@angular/router';
import { KaisatsuResponseError } from '../../../shared/api/response.error';
import { kaisatsuHandleError } from '../../../shared/kaisatsu-error-handler';
import { SpenModule } from '@classi/spen';

@Component({
  standalone: true,
  imports: [SpenModule],
  selector: 'app-login-sso',
  templateUrl: './login-sso.component.html',
  styleUrls: ['./login-sso.component.scss'],
})
export class LoginSsoComponent {
  constructor(
    private readonly ssoAuthService: SsoAuthService,
    private readonly globalMessageService: GlobalMessageService,
    private readonly valueFromAppService: ValueFromAppService,
    private readonly router: Router
  ) {}

  googleLoading = false;
  microsoftLoading = false;

  async loginWithGoogle(): Promise<void> {
    this.googleLoading = true;
    this.globalMessageService.delete();
    const appGoogleLoginUrl = this.valueFromAppService.getGoogleLoginUrl();
    // アプリ用のURLが設定されている場合はそのURLに遷移させる
    if (appGoogleLoginUrl) {
      window.location.href = appGoogleLoginUrl;
    } else {
      try {
        await this.ssoAuthService.googleSignIn();
      } catch (e) {
        if (e instanceof FirebaseError) {
          switch (e.code) {
            // signInWithPopupによるポップアップをユーザーが閉じた場合に発生するエラー
            case 'auth/popup-closed-by-user':
            case 'auth/cancelled-popup-request':
              // SSOの中断と判断してログイン画面をリロードする
              this.router.navigateByUrl('/login/identifier');
              break;
            // signInWithPopupによるポップアップをユーザーがブロックしている場合に発生するエラー
            case 'auth/popup-blocked':
              this.globalMessageService.setErrorMessage({
                general:
                  'ポップアップがブロックされています。Googleのアカウントでログインしたい場合はポップアップを許可してください。',
              });
              break;
            default:
              handleFirebaseError(e);
          }
        } else if (e instanceof DOMException) {
          switch (e.name) {
            // Cookieが許可されていない場合に発生するエラー
            case 'SecurityError':
              this.globalMessageService.setErrorMessage({
                general:
                  'Googleでのログインに失敗しました。Cookieの設定が許可されているかをご確認ください。',
              });
          }
          // singInWithPopupの場合はここで改札APIのエラーハンドリングも必要
        } else if (e instanceof KaisatsuResponseError) {
          // グローバルに表示するエラーメッセージをここで保存
          this.globalMessageService.setErrorMessage(e.messages);
        } else {
          kaisatsuHandleError(e, this.globalMessageService);
        }
      }
    }
    this.googleLoading = false;
  }

  async loginWithMicrosoft(): Promise<void> {
    this.microsoftLoading = true;
    this.globalMessageService.delete();
    const appMicrosoftLoginUrl =
      this.valueFromAppService.getMicrosoftLoginUrl();
    // アプリ用のURLが設定されている場合はそのURLに遷移させる
    if (appMicrosoftLoginUrl) {
      window.location.href = appMicrosoftLoginUrl;
    } else {
      try {
        await this.ssoAuthService.microsoftSignIn();
      } catch (e) {
        if (e instanceof FirebaseError) {
          switch (e.code) {
            // signInWithPopupによるポップアップをユーザーが閉じた場合に発生するエラー
            case 'auth/popup-closed-by-user':
            case 'auth/cancelled-popup-request':
              // SSOの中断と判断してログイン画面をリロードする
              this.router.navigateByUrl('/login/identifier');
              break;
            // signInWithPopupによるポップアップをユーザーがブロックしている場合に発生するエラー
            case 'auth/popup-blocked':
              this.globalMessageService.setErrorMessage({
                general:
                  'ポップアップがブロックされています。Microsoftのアカウントでログインしたい場合はポップアップを許可してください。',
              });
              break;
            default:
              handleFirebaseError(e);
          }
        } else if (e instanceof DOMException) {
          switch (e.name) {
            // Cookieが許可されていない場合に発生するエラー
            case 'SecurityError':
              this.globalMessageService.setErrorMessage({
                general:
                  'Microsoftでのログインに失敗しました。Cookieの設定が許可されているかをご確認ください。',
              });
          }
          // singInWithPopupの場合はここで改札APIのエラーハンドリングも必要
        } else if (e instanceof KaisatsuResponseError) {
          // グローバルに表示するエラーメッセージをここで保存
          this.globalMessageService.setErrorMessage(e.messages);
        } else {
          kaisatsuHandleError(e, this.globalMessageService);
        }
      }
    }
    this.microsoftLoading = false;
  }
}
