<ng-container *ngIf="chooseAccountResponse && renderPage">
  <app-header [logoHeader]="true"></app-header>
  <main>
    <app-global-message></app-global-message>
    <div class="choose-account">
      <section class="status" *ngIf="chooseAccountResponse.sso as sso">
        <div class="label-and-value">
          <p class="label">{{ sso.platform | titlecase }}アカウント</p>
          <p class="value">{{ sso.email }}</p>
        </div>
        <a spenLink class="change-account" (click)="changeSsoAccount()"
          >別のアカウントに切り替える</a
        >
      </section>

      <section class="accounts" *ngIf="chooseAccountResponse.accounts.length">
        <p class="suggestion">どのアカウントでログインしますか？</p>

        <form [formGroup]="form" (submit)="onSubmit()">
          <spen-radio-group formControlName="username">
            <div
              class="account"
              *ngFor="let account of chooseAccountResponse.accounts"
            >
              <spen-radio [value]="account.username">
                <div class="profile">
                  <div class="account-type">
                    {{ account.account_type | accountTypeName }} アカウント(ID:
                    {{ account.username }})
                  </div>
                  <div class="school-name">{{ account.school_name }}</div>
                  <div class="display-name">
                    {{ account.user_display_name }}
                  </div>
                </div>
              </spen-radio>
            </div>
          </spen-radio-group>

          <!-- アプリ側でデフォルトアカウントの切り替え設定ができるようになってから表示する -->
          <!-- <div class="set-default">
            <spen-checkbox formControlName="set_default"></spen-checkbox
            >次回から選択したアカウントでログインする
          </div> -->

          <div class="action-button">
            <button
              spenButton
              color="primary"
              [disabled]="form.invalid || submitInProgress"
            >
              ログインする
            </button>
          </div>
        </form>
      </section>
    </div>
  </main>

  <app-footer></app-footer>
</ng-container>
