import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../api/types';

// spen-headerのcurrentUser属性に使う
export type CurrentUser = {
  userFullName: string;
  userPhotoUrl: string;
  userTypeId: number;
};

const accountTypeToIds = {
  teacher: 1,
  student: 2,
  parent: 3,
};

@Pipe({
  standalone: true,
  name: 'currentUser',
})
export class CurrentUserPipe implements PipeTransform {
  transform(user: User): CurrentUser {
    const currentUser: CurrentUser = {
      userFullName: user.user_display_name,
      userPhotoUrl: user.profile_image_url,
      userTypeId: accountTypeToIds[user.account_type],
    };
    return currentUser;
  }
}
