<spen-heading [level]="3">Classi IDを入力</spen-heading>
<form (submit)="onSubmit()" [formGroup]="form">
  <spen-form-field>
    <input
      #idInput
      spenInput
      name="username"
      autocomplete="username"
      formControlName="username"
      inputmode="email"
      placeholder="入力してください"
      autocapitalize="off"
      autocorrect="off"
      required
    />
    @if (formErrors?.username; as usernameError) {
      <spen-form-error>{{ usernameError }}</spen-form-error>
    }
  </spen-form-field>
  <div class="password">
    <input
      name="password"
      type="password"
      autocomplete="current-password"
      formControlName="password"
      autocapitalize="off"
      autocorrect="off"
    />
  </div>
  <button
    color="primary"
    spenButton
    [additionalClass]="'is-full'"
    [disabled]="form.invalid || submitInProgress"
  >
    パスワード入力へ
  </button>
</form>
