import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TabData } from '@classi/spen';
import { MyService } from '../../shared/api/my.service';
import { KaisatsuResponseError } from '../../shared/api/response.error';
import { SchoolAdminService } from '../../shared/api/school-admin.service';
import { SsoSearchOptions, User } from '../../shared/api/types';
import { GlobalMessageService } from '../../shared/global-message.service';
import { kaisatsuHandleError } from '../../shared/kaisatsu-error-handler';

@Component({
  selector: 'app-school-sso',
  templateUrl: './school-sso.component.html',
  styleUrls: ['./school-sso.component.scss'],
})
export class SchoolSsoComponent implements OnInit, OnDestroy {
  user: User | null = null;

  readonly tabList: TabData[] = [
    { name: 'teacher', label: '先生' },
    { name: 'student', label: '生徒' },
  ];

  selectedTabName: string = 'teacher';

  teacherSearchOptions: SsoSearchOptions | null = null;
  studentSearchOptions: SsoSearchOptions | null = null;

  iframe = false;
  constructor(
    private readonly myService: MyService,
    private readonly schoolAdminService: SchoolAdminService,
    private readonly globalMessageService: GlobalMessageService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title
  ) {}

  async ngOnInit(): Promise<void> {
    this.iframe = this.route.snapshot.queryParamMap.get('iframe') === 'true';
    this.titleService.setTitle(this.route.snapshot.data.title);

    try {
      this.user = await this.myService.getStatuses();
      const searchOptions = await this.schoolAdminService.getSearchOptions();
      this.teacherSearchOptions = searchOptions;
      this.studentSearchOptions = searchOptions;
    } catch (e: KaisatsuResponseError | unknown) {
      if (e instanceof KaisatsuResponseError) {
        // グローバルに表示するエラーメッセージをここで保存
        this.globalMessageService.setErrorMessage(e.messages);
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
    }
  }
  ngOnDestroy(): void {
    this.globalMessageService.delete();
  }

  // type TabName = 'teacher' | 'student'　としたところエラーになってしまった
  // Argument of type 'string' is not assignable to parameter of type 'TabName'.
  clickTab(tabName: string) {
    this.selectedTabName = tabName;
  }

  async teacherSchoolYearChange(school_year: number): Promise<void> {
    this.teacherSearchOptions = await this.schoolAdminService.getSearchOptions({
      school_year,
    });
  }

  async studentSchoolYearChange(school_year: number): Promise<void> {
    this.studentSearchOptions = await this.schoolAdminService.getSearchOptions({
      school_year,
    });
  }
}
