<app-header serviceName="アカウント確認"></app-header>

<main *ngIf="profile">
  <spen-heading [level]="2" headingStyle="size-l" [hasMarginBottom]="true"
    >アカウント確認</spen-heading
  >
  このたびは、Classiをご利用いただき誠にありがとうございます。

  <div class="information">
    <label>こちらは、</label>
    <div class="school-name">{{ profile.school_name }}</div>
    <div class="account-name">
      <span class="font-bold">{{ profile.user_display_name }}</span
      >さんの<span>{{ profile.account_type | accountTypeName }}</span
      >アカウントです。
    </div>
  </div>

  <div class="announce">
    異なる場合は設定を中止し、{{
      profile.account_type === 'teacher'
        ? '学校のClassi管理責任者または管理者の先生'
        : '学校の先生'
    }}にお問い合わせください。
  </div>

  <form (submit)="onSubmit()" [formGroup]="form">
    <spen-checkbox formControlName="confirmation">確認しました</spen-checkbox>
    <div class="form-actions">
      <button
        color="primary"
        spenButton
        class="action-button"
        [disabled]="form.invalid || submitInProgress"
      >
        次へ
      </button>
      <button
        type="button"
        color="danger"
        spenButton
        class="action-button"
        (click)="denyAndBack()"
      >
        設定を中止する
      </button>
    </div>
  </form>
</main>

<app-footer></app-footer>
