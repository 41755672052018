import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../shared/api/login.service';
import { LoginMethod, PasswordLoginMethod } from '../../shared/api/types';
import { RecaptchaService } from '../../shared/recaptcha.service';
import {
  FormErrors,
  KaisatsuResponseError,
} from '../../shared/api/response.error';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalMessageService } from '../../shared/global-message.service';
import { kaisatsuHandleError } from '../../shared/kaisatsu-error-handler';
import { Title } from '@angular/platform-browser';
import { LoginChallengeService } from '../../shared/login-challenge.service';
import { PasswordLoginChallengeParams } from '../login-challenge/password/password-login-challenge.component';

@Component({
  selector: 'app-bc-login-manabi-challenge',
  templateUrl: './bc-login-manabi-challenge.component.html',
  styleUrls: ['./bc-login-manabi-challenge.component.scss'],
})
export class BcLoginManabiChallengeComponent implements OnInit, OnDestroy {
  username = '';
  password = '';
  loginMethods: LoginMethod[] = [];
  formErrors: FormErrors | null = null;
  submitInProgress = false;

  private onDestroy$ = new Subject();

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly loginChallengeService: LoginChallengeService,
    private readonly loginService: LoginService,
    private readonly recaptchaService: RecaptchaService,
    private readonly globalMessageService: GlobalMessageService,
    private readonly titleService: Title
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data.title);

    this.loginChallengeService
      .getInformation()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(({ username, password, loginMethods }) => {
        if (username === null) {
          this.router.navigate(['/bc_login/manabi/begin'], {
            queryParamsHandling: 'preserve',
          });
          return;
        }
        this.username = username;
        this.password = password;
        this.loginMethods = loginMethods;
      });

    // エラーメッセージは毎回リセットする
    this.formErrors = null;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  getPasswordLoginMethod(): PasswordLoginMethod | null {
    function isPasswordLoginMethod(
      loginMethod: LoginMethod
    ): loginMethod is PasswordLoginMethod {
      return loginMethod.name === 'password';
    }

    return this.loginMethods.find(isPasswordLoginMethod) ?? null;
  }

  async onPasswordLoginChallenge(
    params: PasswordLoginChallengeParams
  ): Promise<void> {
    this.submitInProgress = true;
    this.globalMessageService.delete();

    try {
      params.manabi = true;
      await this.loginService.postLoginWithPassword(this.username, params);
      await this.loginService.getLoginContinue();
      this.router.navigateByUrl('/bc_login/manabi/complete');
    } catch (e: KaisatsuResponseError | unknown) {
      // reCAPTCHA Widgetをリセットする
      this.recaptchaService.reset();
      if (e instanceof KaisatsuResponseError) {
        // グローバルに表示するエラーメッセージをここで保存
        this.globalMessageService.setErrorMessage(e.messages);
        switch (e.status) {
          // not_foundエラーの場合は/login/identifierに戻す
          case 404:
            this.router.navigateByUrl('/bc_login/manabi/begin');
            break;
        }
        // フォームに関するエラーがある場合はフォームに渡す
        if (e.messages?.form) {
          this.formErrors = e.messages.form;
        }
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
    }
    this.submitInProgress = false;
  }
}
