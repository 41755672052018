import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { LoginService } from '../../shared/api/login.service';
import { MyService } from '../../shared/api/my.service';
import { KaisatsuResponseError } from '../../shared/api/response.error';
import { BackurlService } from '../../shared/backurl.service';
import { GlobalMessageService } from '../../shared/global-message.service';
import { kaisatsuHandleError } from '../../shared/kaisatsu-error-handler';
import { ValueFromAppService } from '../../shared/value-from-app.service';

@Component({
  selector: 'app-login-complete',
  templateUrl: './login-complete.component.html',
  styleUrls: ['./login-complete.component.scss'],
})
export class LoginCompleteComponent implements OnInit {
  constructor(
    private readonly backurlService: BackurlService,
    private readonly valueFromAppService: ValueFromAppService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private readonly loginService: LoginService,
    private readonly globalMessageService: GlobalMessageService
  ) {}

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle(this.route.snapshot.data.title);

    const appCompleteUrl = this.valueFromAppService.getCompleteUrl();
    const claimType = this.valueFromAppService.getClaimType();
    const backurl = this.backurlService.restore();

    try {
      if (appCompleteUrl && claimType === 'access_token') {
        // access_tokenを発行してもらう
        const access_token = (await this.loginService.issueAccessToken()).token;
        // パラメータにaccess_tokenを載せてリダイレクトする
        window.location.href = `${appCompleteUrl}?access_token=${access_token}`;
      } else {
        // Classi Cookieを発行してもらう
        await this.loginService.issueCookie();
        // appCompleteUrl、backurlの設定がなければplatformへリダイレクト
        window.location.href =
          appCompleteUrl || backurl || environment.platformReferrerUrl;
      }
    } catch (e: KaisatsuResponseError | unknown) {
      if (e instanceof KaisatsuResponseError) {
        // グローバルに表示するエラーメッセージをここで保存
        this.globalMessageService.setErrorMessage(e.messages);
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
      this.router.navigateByUrl('/login/identifier');
    }
  }
}
