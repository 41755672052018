import { Component, ChangeDetectionStrategy } from '@angular/core';
import { SpenLinkModule } from '@classi/spen';

@Component({
  standalone: true,
  selector: 'app-password-notes',
  imports: [SpenLinkModule],
  templateUrl: './password-notes.component.html',
  styleUrls: ['./password-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordNotesComponent {}
