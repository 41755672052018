import { Injectable } from '@angular/core';
import { FirebaseError } from '@angular/fire/app';
import * as Sentry from '@sentry/angular-ivy';
import {
  Auth,
  getRedirectResult,
  OAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { LoginService } from './api/login.service';

@Injectable({
  providedIn: 'root',
})
export class SsoAuthService {
  constructor(
    private readonly auth: Auth,
    private readonly loginService: LoginService,
    private readonly router: Router
  ) {}

  async googleSignIn(): Promise<void> {
    const provider = new OAuthProvider('google.com');
    provider.addScope('email');
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    try {
      /**
       * @see https://firebase.google.com/docs/reference/js/auth.md#signinwithredirect
       */
      // await signInWithRedirect(this.auth, provider);

      // iOS16.1にてsignInWithRedirect後のgetRedirectResultにバグがあり機能していないので、一旦signInWithPopupで実装する
      /**
       * @see https://firebase.google.com/docs/reference/js/auth.md#signinwithpopup
       */
      const result = await signInWithPopup(this.auth, provider);

      if (result) {
        // firebaseではなくoauth providerのidTokenを取得
        const idToken = OAuthProvider.credentialFromResult(result)?.idToken;
        if (idToken) {
          await this.loginService.postLoginWithGoogle(idToken);
          this.router.navigateByUrl('/login/continue');
        }
      }
    } catch (e) {
      throw e;
    }
  }

  async microsoftSignIn(): Promise<void> {
    const provider = new OAuthProvider('microsoft.com');
    provider.addScope('email');
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    try {
      /**
       * @see https://firebase.google.com/docs/reference/js/auth.md#signinwithredirect
       */
      // await signInWithRedirect(this.auth, provider);

      // iOS16.1にてsignInWithRedirect後のgetRedirectResultにバグがあり機能していないので、一旦signInWithPopupで実装する
      /**
       * @see https://firebase.google.com/docs/reference/js/auth.md#signinwithpopup
       */
      const result = await signInWithPopup(this.auth, provider);

      if (result) {
        // firebaseではなくoauth providerのidTokenを取得
        const idToken = OAuthProvider.credentialFromResult(result)?.idToken;
        if (idToken) {
          await this.loginService.postLoginWithMicrosoft(idToken);
          this.router.navigateByUrl('/login/continue');
        }
      }
    } catch (e) {
      throw e;
    }
  }

  async login(): Promise<void> {
    /**
     * @see https://firebase.google.com/docs/reference/js/auth.md#getredirectresult
     */
    const result = await getRedirectResult(this.auth);
    if (result) {
      // firebaseではなくoauth providerのidTokenを取得
      const idToken = OAuthProvider.credentialFromResult(result)?.idToken;
      if (idToken) {
        switch (result.providerId) {
          case 'google.com':
            await this.loginService.postLoginWithGoogle(idToken);
            this.router.navigateByUrl('/login/continue');
            break;
          case 'microsoft.com':
            await this.loginService.postLoginWithMicrosoft(idToken);
            this.router.navigateByUrl('/login/continue');
            break;
        }
      }
    }
  }
}

/**
 *
 * Firebase.auth共通のエラーのハンドリング
 * Sentry監視にて発生回数の多いエラーから順次ハンドリングしていく。
 * @see https://firebase.google.com/docs/reference/js/auth.md#autherrorcodes
 */
export function handleFirebaseError(err: FirebaseError): void {
  switch (err.code) {
    case 'auth/network-request-failed':
      window.alert(
        'サーバーに接続できませんでした。通信環境のご確認、または再度時間を置いてお試しください。'
      );
      break;
    // ユーザーが認証を終えずに操作を終了した際のエラー
    case 'auth/user-cancelled':
      // 何もしない
      break;
    default:
      // 未ハンドリングエラーはコンソールに出力し、Sentryに送信する。
      console.log(err);
      Sentry.captureException(err);
  }
}
