<div class="benesse">
  <img src="../../../assets/img/benesse_logo.svg" />
  <p class="message">
    Benesse High School OnlineのID または「SASSI/SASPI」から始まるIDをお持ちの方
  </p>
  <button
    spenButton
    [additionalClass]="'is-full'"
    (click)="benesseLogin.open()"
  >
    上記のIDでログイン
  </button>
</div>
<!-- ベネッセログイン画面へのリンクダイアログ -->
<spen-dialog #benesseLogin>
  <spen-dialog-title>ベネッセ IDのログイン</spen-dialog-title>
  <div class="dialog-content">
    <p>それぞれのログイン画面へ進んでください。</p>
    <div class="benesse-links">
      <a spenButton [href]="benesseLoginUrl.teacher" class="link">
        <img src="assets/img/benesse-login/icon-teacher.png" />
        <label
          ><p>先生向け</p>
          <p>ログイン</p></label
        >
      </a>
      <a spenButton [href]="benesseLoginUrl.student" class="link">
        <img src="assets/img/benesse-login/icon-student.png" />
        <label
          ><p>生徒向け</p>
          <p>ログイン</p></label
        >
      </a>
      <a spenButton [href]="benesseLoginUrl.parent" class="link">
        <img src="assets/img/benesse-login/icon-parent.png" />
        <label>
          <p>保護者向け</p>
          <p>ログイン</p>
        </label>
      </a>
    </div>
  </div>
</spen-dialog>
