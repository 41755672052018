/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { KaisatsuCsrfTokenInterceptor } from './kaisatsu-csrf-token.interceptor';
import { ErrorHandlingInterceptor } from './error-handling.interceptor';
import { WithCredentialsInterceptor } from './with-credentials.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: WithCredentialsInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorHandlingInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: KaisatsuCsrfTokenInterceptor,
    multi: true,
  },
];
