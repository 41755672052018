import { Injectable } from '@angular/core';
import { isPermittedAppUrl } from './is-permitted-app-url';

@Injectable({
  providedIn: 'root',
})
export class ValueFromAppService {
  constructor() {}

  getGoogleLoginUrl(): string | null {
    const key = 'kaisatsu:app_google_oauth_url';
    try {
      const googleLoginUrl = localStorage.getItem(key);
      return googleLoginUrl && isPermittedAppUrl(googleLoginUrl)
        ? googleLoginUrl
        : null;
    } catch {
      return null;
    }
  }

  getMicrosoftLoginUrl(): string | null {
    const key = 'kaisatsu:app_microsoft_oauth_url';
    try {
      const microsoftLoginUrl = localStorage.getItem(key);
      return microsoftLoginUrl && isPermittedAppUrl(microsoftLoginUrl)
        ? microsoftLoginUrl
        : null;
    } catch {
      return null;
    }
  }

  getCompleteUrl(): string | null {
    const key = 'kaisatsu:app_complete_url';
    try {
      const completeUrl = localStorage.getItem(key);
      return completeUrl && isPermittedAppUrl(completeUrl) ? completeUrl : null;
    } catch {
      return null;
    }
  }

  getFallbackUrl(): string | null {
    const key = 'kaisatsu:app_fallback_url';
    try {
      const fallbackUrl = localStorage.getItem(key);
      return fallbackUrl && isPermittedAppUrl(fallbackUrl) ? fallbackUrl : null;
    } catch {
      return null;
    }
  }

  getClaimType(): string | null {
    const key = 'kaisatsu:app_claim_type';
    try {
      return localStorage.getItem(key);
    } catch {
      return null;
    }
  }
}
