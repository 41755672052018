import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  DomSanitizer,
  SafeResourceUrl,
  Title,
} from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../shared/api/login.service';
import {
  FormErrors,
  KaisatsuResponseError,
} from '../../shared/api/response.error';
import { GlobalMessageService } from '../../shared/global-message.service';
import { kaisatsuHandleError } from '../../shared/kaisatsu-error-handler';

export type TosResponse = {
  tos_type: 'eula' | 'notice';
  tos_url: string;
};

@Component({
  selector: 'app-login-tos-agreement',
  templateUrl: './login-tos-agreement.component.html',
  styleUrls: ['./login-tos-agreement.component.scss'],
})
export class LoginTosAgreementComponent implements OnInit, OnDestroy {
  tosUrl: SafeResourceUrl | null = null;
  tosType: 'eula' | 'notice' | null = null;
  title: string | null = null;
  readonly tosForm = this.fb.group({});

  formErrors: FormErrors | null = null;
  submitInProgress = false;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly router: Router,
    private readonly loginService: LoginService,
    private readonly globalMessageService: GlobalMessageService,
    private readonly sanitizer: DomSanitizer,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title
  ) {}

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle(this.route.snapshot.data.title);

    try {
      const tosResponse = await this.loginService.getTos();
      this.tosUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        tosResponse.tos_url
      );
      this.tosType = tosResponse.tos_type;
      // tos_typeが'eula'の場合「利用規約」としてチェックボックスを表示
      if (this.tosType === 'eula') {
        this.title = '利用規約';
        this.tosForm.addControl(
          'agreement',
          this.fb.control(false, {
            validators: [Validators.requiredTrue],
          })
        );
        // tos_typeが'notice'の場合「注意事項」と表記する
      } else if (this.tosType === 'notice') {
        this.title = '注意事項';
      }
    } catch (e: KaisatsuResponseError | unknown) {
      if (e instanceof KaisatsuResponseError) {
        // グローバルに表示するエラーメッセージをここで保存
        this.globalMessageService.setErrorMessage(e.messages);
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
    }
  }

  ngOnDestroy(): void {
    this.globalMessageService.delete();
  }

  async onTosFormSubmit(): Promise<void> {
    this.submitInProgress = true;
    try {
      await this.loginService.PostTosAgreement();
      this.router.navigateByUrl('/login/continue');
    } catch (e: KaisatsuResponseError | unknown) {
      if (e instanceof KaisatsuResponseError) {
        // グローバルに表示するエラーメッセージをここで保存
        this.globalMessageService.setErrorMessage(e.messages);
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
    }
    this.submitInProgress = false;
  }
}
