<form [formGroup]="form" (submit)="download()">
  <div class="panel">
    <div class="panel-line select-box-field">
      <div class="form-field">
        <spen-label>年度</spen-label>
        <spen-select
          formControlName="school_year"
          [optionList]="schoolYearOptions"
          [responsive]="true"
          (changeSelectOption)="changeSchoolYear.emit($event)"
        ></spen-select>
      </div>
      <div class="form-field">
        <spen-label>学校種別</spen-label>
        <spen-select
          formControlName="stage_id"
          [optionList]="schoolStageOptions"
          [responsive]="true"
          (changeSelectOption)="selectStage()"
        ></spen-select>
      </div>

      <div class="form-field">
        <spen-label>学年</spen-label>
        <ng-container
          *ngIf="isGradeEnabled; then enabledGrade; else disabledGrade"
        ></ng-container>
        <ng-template #enabledGrade>
          <spen-select
            formControlName="grade_id"
            [optionList]="gradeOptions"
            [responsive]="true"
          ></spen-select>
        </ng-template>
        <ng-template #disabledGrade>
          <spen-select [responsive]="true" [disabled]="true"></spen-select>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="actions">
    <button
      color="primary"
      spenButton
      class="action-button"
      [disabled]="form.invalid || downloadInProgress"
    >
      ファイルをダウンロードする
    </button>
  </div>
</form>
