import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FormErrors } from '../../../shared/api/response.error';
import { PasswordLoginMethod } from '../../../shared/api/types';
import { RecaptchaService } from '../../../shared/recaptcha.service';
import { PasswordLoginChallengeParams } from '../../login-challenge/password/password-login-challenge.component';

@Component({
  selector: 'app-password-bc-login-manabi-challenge',
  templateUrl: './password-bc-login-manabi-challenge.component.html',
  styleUrls: ['./password-bc-login-manabi-challenge.component.scss'],
})
export class PasswordBcLoginManabiChallengeComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input()
  password = '';
  @Input()
  loginMethod!: PasswordLoginMethod;
  @Input()
  formErrors: FormErrors | null = null;
  @Input()
  submitInProgress = false;

  @Output()
  challenge = new EventEmitter<PasswordLoginChallengeParams>();

  @ViewChild('grecaptchaContainer')
  grecaptchaContainer: ElementRef<HTMLElement> | null = null;
  @ViewChild('passwordInput', { read: ElementRef, static: false })
  passwordInput: ElementRef<HTMLElement> | null = null;

  readonly form = this.fb.group({
    password: this.fb.control('', {
      validators: [Validators.required],
    }),
  });

  isPasswordVisible = false;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly recaptchaService: RecaptchaService
  ) {}

  ngOnInit(): void {
    this.form.controls.password.setValue(this.password);
  }

  ngAfterViewInit(): void {
    // ページを開いた時にパスワード入力欄にフォーカス
    if (this.passwordInput) {
      this.passwordInput.nativeElement.focus();
    }
    if (this.grecaptchaContainer) {
      const recaptchaControl = this.fb.control(null, {
        validators: [Validators.required],
      });
      this.form.addControl('g-recaptcha-response', recaptchaControl);

      this.recaptchaService
        .render(this.grecaptchaContainer.nativeElement)
        .subscribe(
          (token) => {
            recaptchaControl.setValue(token);
          },
          (error) => {
            recaptchaControl.setValue(null);
          }
        );
    }
  }

  ngOnDestroy(): void {
    this.recaptchaService.destroyWidget();
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  onSubmit() {
    this.challenge.emit({ ...this.form.value, saveId: false });
  }
}
