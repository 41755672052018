import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../shared/api/login.service';
import { KaisatsuResponseError } from '../../shared/api/response.error';
import { ChooseAccountResponse } from '../../shared/api/types';
import { GlobalMessageService } from '../../shared/global-message.service';
import { kaisatsuHandleError } from '../../shared/kaisatsu-error-handler';

@Component({
  selector: 'app-login-choose-account',
  templateUrl: './login-choose-account.component.html',
  styleUrls: ['./login-choose-account.component.scss'],
})
export class LoginChooseAccountComponent implements OnInit {
  readonly form = this.fb.group({
    username: this.fb.control('', { validators: [Validators.required] }),
    set_default: this.fb.control(false, {}),
  });
  chooseAccountResponse: ChooseAccountResponse | null = null;
  renderPage = false;
  submitInProgress = false;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly router: Router,
    private readonly loginService: LoginService,
    private readonly globalMessageService: GlobalMessageService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title
  ) {}

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle(this.route.snapshot.data.title);

    try {
      this.chooseAccountResponse = await this.loginService.getChooseAccount();
      const accounts = this.chooseAccountResponse?.accounts;

      // メールアドレスに紐づいているアカウントが１つなら自動でそのアカウントを選択し/login/completeへ
      if (accounts.length === 1) {
        this.form.setValue({
          username: accounts[0].username,
          set_default: false,
        });
        await this.loginService.postChooseAccount(this.form.value);
        this.router.navigate(['/login/complete'], {
          queryParamsHandling: 'preserve',
        });
      }
    } catch (e: KaisatsuResponseError | unknown) {
      if (e instanceof KaisatsuResponseError) {
        // グローバルに表示するエラーメッセージをここで保存
        this.globalMessageService.setErrorMessage(e.messages);
        this.router.navigateByUrl('/login/identifier');
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
    }
    this.renderPage = true;
  }

  async changeSsoAccount(): Promise<void> {
    this.router.navigateByUrl('/login/identifier');
  }

  async onSubmit(): Promise<void> {
    this.submitInProgress = true;
    try {
      await this.loginService.postChooseAccount(this.form.value);
      this.router.navigateByUrl('/login/continue');
    } catch (e: KaisatsuResponseError | unknown) {
      if (e instanceof KaisatsuResponseError) {
        // グローバルに表示するエラーメッセージをここで保存
        this.globalMessageService.setErrorMessage(e.messages);
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
    }
    this.submitInProgress = false;
  }
}
