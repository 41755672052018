<section class="user-list" *ngIf="usersList">
  <spen-heading [level]="2" [headingStyle]="'size-s'"
    >{{ accountType | accountTypeName }}一覧 (全{{
      usersList.count
    }}件)</spen-heading
  >
  <div class="table">
    <div class="header">
      <div class="cell identifier">識別番号</div>
      <div class="cell name">氏名</div>
      <div class="cell username">Classi ID</div>
      <div class="cell sso">SSOアカウント</div>
      <div class="cell action-button"></div>
    </div>

    <div class="body" *ngIf="usersList.records as users">
      <div class="row" *ngFor="let user of users">
        <div class="cell identifier">
          {{ user.identifier }}
        </div>
        <div class="cell name">
          <div>{{ user.name }}</div>
          <div class="profile">{{ user.profile }}</div>
        </div>
        <div class="cell username">{{ user.username }}</div>
        <div class="cell sso">
          <ng-container
            *ngIf="
              user.google_account || user.microsoft_account;
              then accountLinked;
              else noAccountLinked
            "
          ></ng-container>
          <ng-template #accountLinked>
            <div class="google" *ngIf="user.google_account">
              <spen-status-information-label [style.font-size.px]="12"
                >Google</spen-status-information-label
              >{{ user.google_account }}
            </div>
            <div class="microsoft" *ngIf="user.microsoft_account">
              <spen-status-information-label [style.font-size.px]="12"
                >Microsoft</spen-status-information-label
              >{{ user.microsoft_account }}
            </div>
          </ng-template>
          <ng-template #noAccountLinked>
            <div class="no-account">未設定</div>
          </ng-template>
        </div>
        <div class="cell action-button">
          <button spenButton (click)="accountEditDialog.open(user)">
            編集する
          </button>
        </div>
      </div>
    </div>
  </div>
  <spen-paginator
    *ngIf="usersList.rels && usersList.rels.last !== 1"
    [length]="usersList.rels.last"
    [pageIndex]="usersList.rels.current - 1"
    (page)="onPage($event)"
  ></spen-paginator>
</section>

<app-sso-users-account-edit-dialog
  #accountEditDialog
  (ssoUserUpdate)="ssoUserUpdate.emit()"
></app-sso-users-account-edit-dialog>
