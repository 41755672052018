import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { SpenSelectOption } from '@classi/spen';
import {
  FormErrors,
  KaisatsuResponseError,
} from '../../../shared/api/response.error';
import { SchoolAdminService } from '../../../shared/api/school-admin.service';
import { GlobalMessageService } from '../../../shared/global-message.service';
import { kaisatsuHandleError } from '../../../shared/kaisatsu-error-handler';

@Component({
  selector: 'app-upload-form-sso-users',
  templateUrl: './upload-form-sso-users.component.html',
  styleUrls: ['./upload-form-sso-users.component.scss'],
})
export class UploadFormSsoUsersComponent {
  uploadInProgress = false;
  formErrors: FormErrors | null = null;

  readonly accountTypeList: SpenSelectOption[] = [
    { text: '先生', value: 'teacher' },
    { text: '生徒', value: 'student' },
  ];
  readonly form = this.fb.group({
    account_type: this.fb.control('', [Validators.required]),
    input_csv: this.fb.control('', [Validators.required]),
    file: this.fb.control('', [Validators.required]),
  });
  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly schoolAdminService: SchoolAdminService,
    private readonly globalMessageService: GlobalMessageService
  ) {}

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    this.form.controls.file.setValue(file);
  }

  async upload(): Promise<void> {
    this.uploadInProgress = true;
    // アップロード前にそれまでのformErrorsとglobalMessageの表示を空にする
    this.formErrors = null;
    this.globalMessageService.delete();

    const accountType = this.form.controls.account_type.value;
    const file = this.form.controls.file.value;
    const formData = new FormData();
    formData.append('file', file, file.name);

    try {
      switch (accountType) {
        case 'teacher':
          await this.schoolAdminService.postSsoTeachersCsv(formData);
          break;
        case 'student':
          await this.schoolAdminService.postSsoStudentsCsv(formData);
          break;
      }

      this.globalMessageService.setSuccessMessage(
        `${file.name} のアップロードが完了しました。`
      );
      this.resetUploadForm();
    } catch (e: KaisatsuResponseError | unknown) {
      this.resetUploadForm();
      if (e instanceof KaisatsuResponseError) {
        // グローバルに表示するエラーメッセージをここで保存
        this.globalMessageService.setErrorMessage(e.messages);
        // フォームに関するエラーがある場合はフォームに渡す
        if (e.messages?.form) {
          this.formErrors = e.messages.form;
        }
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
    }
    this.uploadInProgress = false;
  }

  private resetUploadForm(): void {
    this.form.reset();
    // 「選択してください」を表示するために空文字をセットする
    this.form.controls.account_type.setValue('');
  }
}
