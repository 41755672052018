<app-header
  *ngIf="user"
  [user]="user"
  serviceName="ログイン情報変更"
></app-header>

<main>
  <spen-heading [level]="2" [headingStyle]="'size-l'"
    >ログイン情報変更</spen-heading
  >
  <app-global-message></app-global-message>

  <div class="description-box">
    <p>
      ClassiID、パスワード<span *ngIf="emailSettable">、メールアドレス</span
      >を登録・変更します。
    </p>
    <div class="email-setting" *ngIf="emailSettable">
      <button
        spenButton
        routerLink="/login/email_setting"
        [queryParams]="{ iframe }"
      >
        メールアドレスの登録・変更へ
      </button>
    </div>
  </div>

  <section class="username">
    <spen-heading [level]="2" [headingStyle]="'size-s'"
      >Classi IDの変更</spen-heading
    >
    <div class="description">
      変更したい場合は下記に表示されているIDを変えてください。
    </div>

    <app-username-setting-form
      *ngIf="user"
      [currentUsername]="user.username"
    ></app-username-setting-form>
  </section>

  <section class="password">
    <spen-heading [level]="2" [headingStyle]="'size-s'"
      >パスワードの設定</spen-heading
    >
    <div class="description">
      以下のルールに沿って、新しいパスワードを設定してください。
      <app-password-notes></app-password-notes>
    </div>

    <app-password-setting-form></app-password-setting-form>
  </section>
</main>

<app-footer [user]="user"></app-footer>
