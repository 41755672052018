import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KaisatsuResponseError } from '../../../shared/api/response.error';
import { SchoolAdminService } from '../../../shared/api/school-admin.service';
import {
  CurrentFilterSet,
  PageParams,
  SearchSchoolYear,
  SsoSearchOptions,
  SsoUsersList,
  SsoUsersSearchParams,
  UsersSearchParams,
} from '../../../shared/api/types';
import { GlobalMessageService } from '../../../shared/global-message.service';
import { kaisatsuHandleError } from '../../../shared/kaisatsu-error-handler';
import { SsoStudentsService } from '../../../shared/sso-students.service';
import { changeUsersSearchParams } from '../change-users-search-params';

@Component({
  selector: 'app-sso-students',
  templateUrl: './sso-students.component.html',
  styleUrls: ['./sso-students.component.scss'],
})
export class SsoStudentsComponent implements OnInit, OnDestroy {
  @Input()
  searchOptions!: SsoSearchOptions;

  @Output()
  schoolYearChange = new EventEmitter<number>();

  currentFilter: CurrentFilterSet = {
    school_year: null,
    stage_id: null,
    grade_id: null,
    classroom_id: null,
    attendance_number: null,
    email: null,
  };

  studentsList: SsoUsersList = { count: 0 };
  searchInProgress = false;

  private onDestroy$ = new Subject();

  constructor(
    private readonly schoolAdminService: SchoolAdminService,
    private readonly ssoStudentsService: SsoStudentsService,
    private readonly globalMessageService: GlobalMessageService
  ) {}

  ngOnInit(): void {
    this.ssoStudentsService
      .getCurrentFilterAndStudents()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async (state) => {
        // Filterと検索結果の情報が保存されていればそれを表示
        if (state?.currentFilter) {
          this.currentFilter = state.currentFilter;
          this.studentsList = state.usersList;
        } else {
          // ない場合は初期状態(取得したsearchOptionsのschool_yearがselectedの年度)の生徒一覧を取得
          if (this.selectedSchoolYear) {
            const selectedSchoolYear = this.selectedSchoolYear.year;
            this.currentFilter.school_year = selectedSchoolYear;
            await this.searchStudents({
              school_year: selectedSchoolYear,
            });
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  currentFilterUpdate(value: Partial<CurrentFilterSet>): void {
    if (typeof value.school_year === 'number') {
      this.schoolYearChange.emit(value.school_year);
    }
    this.currentFilter = {
      ...this.currentFilter,
      ...value,
    };
    // 現在の検索条件と生徒一覧を保存
    this.ssoStudentsService.setCurrentFilterAndStudents(
      this.currentFilter,
      this.studentsList
    );
  }

  async searchStudents(params: SsoUsersSearchParams): Promise<void> {
    this.searchInProgress = true;
    try {
      this.studentsList =
        await this.schoolAdminService.getSsoStudentsList(params);
      // 現在の検索条件と生徒一覧を保存
      this.ssoStudentsService.setCurrentFilterAndStudents(
        this.currentFilter,
        this.studentsList
      );
    } catch (e: KaisatsuResponseError | unknown) {
      if (e instanceof KaisatsuResponseError) {
        // グローバルに表示するエラーメッセージをここで保存
        this.globalMessageService.setErrorMessage(e.messages);
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
    }
    this.searchInProgress = false;
  }

  private get selectedSchoolYear(): SearchSchoolYear | undefined {
    return this.searchOptions.school_years.find(
      (school_year) => school_year.selected
    );
  }

  async getPageStudents(pageParams: PageParams): Promise<void> {
    const params = {
      ...this.currentUsersSearchParams,
      ...pageParams,
    };

    await this.searchStudents(params);
  }

  async refreshStudents(): Promise<void> {
    await this.searchStudents({
      ...this.currentUsersSearchParams,
      ...this.currentPageParams,
    });
  }

  private get currentUsersSearchParams(): UsersSearchParams {
    return changeUsersSearchParams(this.currentFilter);
  }

  private get currentPageParams(): PageParams {
    return { per_page: 10, page: this.studentsList.rels?.current || 1 };
  }
}
