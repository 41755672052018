import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SpenFooterModule, SpenLinkModule } from '@classi/spen';
import { environment } from '../../../environments/environment';
import { User } from '../api/types';
import { BackurlService } from '../backurl.service';
import { isThirdPartyLoginUrl } from '../is-third-party-login-url';
import { CurrentUserPipe } from '../pipes/current-user.pipe';

@Component({
  standalone: true,
  selector: 'app-footer',
  imports: [CommonModule, SpenFooterModule, SpenLinkModule, CurrentUserPipe],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  @Input()
  user: User | null = null;

  hiddenLink: boolean = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly backurlService: BackurlService
  ) {}

  ngOnInit(): void {
    const backurl = this.backurlService.get();
    if (backurl) {
      this.hiddenLink = isThirdPartyLoginUrl(backurl);
    }
  }

  noFooterRequired(): boolean {
    // iframe=trueのパラメータがある場合はヘッダーを非表示にする
    return this.route.snapshot.queryParamMap.get('iframe') === 'true';
  }
}
