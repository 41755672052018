import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LoginMethod } from './api/types';
import loginChallengeSlice from './store/login-challenge.slice';

export type LoginChallengeInformation = {
  username: string;
  password: string;
  loginMethods: LoginMethod[];
};

@Injectable({
  providedIn: 'root',
})
export class LoginChallengeService {
  constructor(private readonly store: Store<unknown>) {}

  setInformation(LoginChallengeInformation: LoginChallengeInformation): void {
    this.store.dispatch(
      loginChallengeSlice.actions.setInformation(LoginChallengeInformation)
    );
  }
  getInformation(): Observable<LoginChallengeInformation> {
    return loginChallengeSlice.select(this.store, (state: any) => state);
  }
}
