import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

declare global {
  interface Window {
    recaptchaCallback: Function;
  }
}

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {
  private widgetId: number | null = null;
  private readonly scriptId = 'recaptcha-script';

  private readonly recaptchaResponseSubject = new Subject<string | null>();
  private readonly config: ReCaptchaV2.Parameters = {
    sitekey: environment.recaptchaSitekey,
    // 成功時に呼ばれるコールバック
    callback: (token) => {
      this.ngZone.run(() => this.recaptchaResponseSubject.next(token));
    },
    // エラー時に呼ばれるコールバック
    'error-callback': () =>
      this.ngZone.run(() =>
        this.recaptchaResponseSubject.error(new Error('g-recaptcha error'))
      ),
    // 時間が切れた時に呼ばれるコールバック
    'expired-callback': () =>
      this.ngZone.run(() => this.recaptchaResponseSubject.next(null)),
  };

  private grecaptchaLoaded: boolean = false;

  constructor(private readonly ngZone: NgZone) {}

  render(container: HTMLElement): Observable<string | null> {
    if (this.grecaptchaLoaded) {
      this.renderRecaptcha(container);
    } else {
      this.registerRecaptchaCallback(container);
      this.addScript();
    }
    return this.recaptchaResponseSubject.asObservable();
  }

  reset(): void {
    if (this.widgetId !== null) {
      window.grecaptcha.reset();
      this.recaptchaResponseSubject.next(null);
    }
  }

  destroyWidget(): void {
    this.widgetId = null;
  }

  private registerRecaptchaCallback(element: HTMLElement) {
    window.recaptchaCallback = () => {
      this.grecaptchaLoaded = true;
      this.renderRecaptcha(element);
    };
  }

  private renderRecaptcha(element: HTMLElement) {
    this.widgetId = window.grecaptcha.render(element, this.config);
  }

  private addScript() {
    if (document.getElementById(this.scriptId) != null) {
      return;
    }

    const script = document.createElement('script');
    script.src =
      'https://www.google.com/recaptcha/api.js?onload=recaptchaCallback&render=explicit';
    script.id = this.scriptId;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }
}
