<spen-dialog #accountEditDialog (afterClosed)="onAfterClosed()">
  <spen-dialog-title>SSO連携情報編集</spen-dialog-title>
  <app-global-message [isMarginTop]="false"></app-global-message>
  <div *ngIf="user">
    <div class="form-field">
      <spen-label>氏名</spen-label>
      <div class="name">{{ user.name }}</div>
    </div>
    <div class="form-field">
      <spen-label>Googleアカウント</spen-label>
      <div class="email">
        <form
          [formGroup]="googleAccountForm"
          (submit)="
            googleAccountForm.disabled
              ? unlinkGoogleAccount()
              : linkGoogleAccount()
          "
        >
          <spen-form-field>
            <input
              spenInput
              formControlName="google_account"
              placeholder="入力してください"
              autocapitalize="off"
              autocorrect="off"
              [disabled]="googleAccountForm.disabled"
            />

            <!-- 既にアカウントが連携済みなら解除ボタン、そうでないなら設定ボタンを表示 -->
            <button
              *ngIf="googleAccountForm.disabled"
              spenButton
              color="danger"
            >
              解除する
            </button>
            <button
              *ngIf="!googleAccountForm.disabled"
              spenButton
              color="primary"
              [disabled]="googleAccountForm.invalid || linkInProgress"
            >
              設定する
            </button>

            <spen-form-error
              *ngIf="
                googleAccountForm.controls.google_account.errors?.email &&
                (googleAccountForm?.dirty || googleAccountForm?.touched)
              "
              >メールアドレスの形式が正しくありません。</spen-form-error
            >
          </spen-form-field>
        </form>
      </div>
    </div>
    <div class="form-field">
      <spen-label>Microsoftアカウント</spen-label>
      <div class="email">
        <form
          [formGroup]="microsoftAccountForm"
          (submit)="
            microsoftAccountForm.disabled
              ? unlinkMicrosoftAccount()
              : linkMicrosoftAccount()
          "
        >
          <spen-form-field>
            <input
              spenInput
              formControlName="microsoft_account"
              placeholder="入力してください"
              autocapitalize="off"
              autocorrect="off"
              [disabled]="microsoftAccountForm.disabled"
            />

            <button
              *ngIf="microsoftAccountForm.disabled"
              spenButton
              color="danger"
            >
              解除する
            </button>
            <button
              *ngIf="!microsoftAccountForm.disabled"
              spenButton
              color="primary"
              [disabled]="microsoftAccountForm.invalid || linkInProgress"
            >
              設定する
            </button>

            <spen-form-error
              *ngIf="
                microsoftAccountForm.controls.microsoft_account.errors?.email &&
                (microsoftAccountForm?.dirty || microsoftAccountForm?.touched)
              "
              >メールアドレスの形式が正しくありません。</spen-form-error
            >
          </spen-form-field>
        </form>
      </div>
    </div>
  </div>
</spen-dialog>
