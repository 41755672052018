import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { handleErrorResponse } from './response.error';
import { SchoolLoginOptions } from './types';

const baseUrl = `${environment.kaisatsuApiBaseUrl}/api`;

@Injectable({
  providedIn: 'root',
})
export class SchoolService {
  constructor(private readonly http: HttpClient) {}

  /**
   * @see https://kaisatsu.stg.classi.jp/docs/api/index.html
   */

  async getLoginOptions(): Promise<SchoolLoginOptions> {
    const response = await this.http
      .get<{
        success: true;
        data: SchoolLoginOptions;
      }>(`${baseUrl}/v1/school/login_options`)
      .toPromise()
      .catch(handleErrorResponse);

    return response.data;
  }
}
