import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../shared/api/login.service';
import { KaisatsuResponseError } from '../../shared/api/response.error';
import { GlobalMessageService } from '../../shared/global-message.service';
import { kaisatsuHandleError } from '../../shared/kaisatsu-error-handler';

export type LoginProfile = {
  username: string;
  account_type: 'teacher' | 'student' | 'parent';
  school_name: string;
  user_display_name: string;
};

@Component({
  selector: 'app-login-profile-confirmation',
  templateUrl: './login-profile-confirmation.component.html',
  styleUrls: ['./login-profile-confirmation.component.scss'],
})
export class LoginProfileConfirmationComponent implements OnInit {
  constructor(
    private readonly titleService: Title,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly loginService: LoginService,
    private readonly fb: UntypedFormBuilder,
    private readonly globalMessageService: GlobalMessageService
  ) {}

  readonly form = this.fb.group({
    confirmation: this.fb.control(false, {
      validators: [Validators.requiredTrue],
    }),
  });
  submitInProgress = false;

  profile: LoginProfile | null = null;

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle(this.route.snapshot.data.title);
    try {
      this.profile = await this.loginService.getLoginProfile();
    } catch (e: KaisatsuResponseError | unknown) {
      if (e instanceof KaisatsuResponseError) {
        // グローバルに表示するエラーメッセージをここで保存
        this.globalMessageService.setErrorMessage(e.messages);
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
    }
  }

  async onSubmit() {
    this.submitInProgress = true;
    try {
      await this.loginService.postProfileConfirmation();
    } catch (e: KaisatsuResponseError | unknown) {
      if (e instanceof KaisatsuResponseError) {
        // グローバルに表示するエラーメッセージをここで保存
        this.globalMessageService.setErrorMessage(e.messages);
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
    }
    this.submitInProgress = false;
    this.router.navigateByUrl('/login/continue');
  }

  async denyAndBack() {
    // ログアウトする
    try {
      await this.loginService.deleteSession();
    } catch (e: KaisatsuResponseError | unknown) {
      if (e instanceof KaisatsuResponseError) {
        // グローバルに表示するエラーメッセージをここで保存
        this.globalMessageService.setErrorMessage(e.messages);
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
    }
    this.router.navigateByUrl('/login/identifier');
  }
}
