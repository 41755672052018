import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MyService } from '../../shared/api/my.service';
import { KaisatsuResponseError } from '../../shared/api/response.error';
import { User } from '../../shared/api/types';
import { GlobalMessageService } from '../../shared/global-message.service';
import { kaisatsuHandleError } from '../../shared/kaisatsu-error-handler';
import { SchoolService } from '../../shared/api/school.service';

@Component({
  selector: 'app-login-setting',
  templateUrl: './login-setting.component.html',
  styleUrls: ['./login-setting.component.scss'],
})
export class LoginSettingComponent implements OnInit, OnDestroy {
  user: User | null = null;
  emailSettable = false;
  iframe = false;
  constructor(
    private readonly myService: MyService,
    private readonly schoolService: SchoolService,
    private readonly globalMessageService: GlobalMessageService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title
  ) {}

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle(this.route.snapshot.data.title);
    this.iframe = this.route.snapshot.queryParamMap.get('iframe') === 'true';

    try {
      this.user = await this.myService.getStatuses();
      const passwordResetByEmail = (await this.schoolService.getLoginOptions())
        .password_reset_by_email;
      // 保護者またはメールアドレス登録が許可されている場合はメールアドレスの登録・変更を許可する
      if (
        this.user.account_type === 'parent' ||
        passwordResetByEmail[this.user.account_type]
      ) {
        this.emailSettable = true;
      }
    } catch (e: KaisatsuResponseError | unknown) {
      if (e instanceof KaisatsuResponseError) {
        // グローバルに表示するエラーメッセージをここで保存
        this.globalMessageService.setErrorMessage(e.messages);
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
    }
  }
  ngOnDestroy(): void {
    this.globalMessageService.delete();
  }
}
