import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LoginService } from '../../shared/api/login.service';
import {
  FormErrors,
  KaisatsuResponseError,
} from '../../shared/api/response.error';
import { GlobalMessageService } from '../../shared/global-message.service';
import { Subject } from 'rxjs';
import { kaisatsuHandleError } from '../../shared/kaisatsu-error-handler';
import { Title } from '@angular/platform-browser';
import { LoginChallengeService } from '../../shared/login-challenge.service';
import { BcLoginManabiBeginFormComponent } from './bc-login-manabi-begin-form/bc-login-manabi-begin-form.component';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../../shared/header/header.component';
import { FooterComponent } from '../../shared/footer/footer.component';
import { GlobalMessageComponent } from '../../shared/global-message/global-message.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    FooterComponent,
    GlobalMessageComponent,
    BcLoginManabiBeginFormComponent,
  ],
  selector: 'app-bc-login-manabi-begin',
  templateUrl: './bc-login-manabi-begin.component.html',
  styleUrls: ['./bc-login-manabi-begin.component.scss'],
})
export class BcLoginManabiBeginComponent implements OnInit, OnDestroy {
  formErrors: FormErrors | null = null;

  private onDestroy$ = new Subject();

  loginErrorMessages: { [key: string]: string } = {
    school_not_found:
      '貴校はご利用対象外か、もしくはご利用開始前のためこちらのページを表示することができません。',
    user_not_linked:
      'あなたの情報が登録されていません。先生にお問い合わせ下さい。',
    user_disabled: '利用停止中です。',
    license_not_found:
      'ログインに失敗しました。先生に「利用サービス設定」をご確認ください。',
    bhso_login_failed: '認証キーが不正です。',
  };

  renderPage = false;
  submitInProgress = false;

  constructor(
    private readonly router: Router,
    private readonly loginService: LoginService,

    private readonly globalMessageService: GlobalMessageService,
    private readonly route: ActivatedRoute,
    private readonly loginChallengeService: LoginChallengeService,
    private titleService: Title
  ) {}

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle(this.route.snapshot.data.title);

    // エラーメッセージは毎回リセットする
    this.formErrors = null;

    // errorパラメータがある場合はそれに合わせたエラーメッセージを表示する
    const errorCode = this.route.snapshot.queryParamMap.get('error');
    if (errorCode) {
      if (this.loginErrorMessages[errorCode]) {
        this.globalMessageService.setErrorMessage({
          general: this.loginErrorMessages[errorCode],
          displayInquiry: errorCode === 'school_not_found',
        });
      }
      // errorパラメータはアドレスバーから削除する
      history.replaceState('', '', new URL(window.location.href).pathname);
    }
    this.renderPage = true;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.globalMessageService.delete();
  }

  async getLogin(params: {
    username: string;
    password: string;
  }): Promise<void> {
    this.submitInProgress = true;
    try {
      const loginMethods = await this.loginService.getLoginMethods(
        params.username,
        { manabi: true }
      );

      this.loginChallengeService.setInformation({
        ...params,
        loginMethods,
      });

      this.router.navigate(['/bc_login/manabi/challenge'], {
        queryParamsHandling: 'preserve',
      });
    } catch (e: KaisatsuResponseError | unknown) {
      if (e instanceof KaisatsuResponseError) {
        // グローバルに表示するエラーメッセージをここで保存
        this.globalMessageService.setErrorMessage(e.messages);
        // フォームに関するエラーがある場合はフォームに渡す
        if (e.messages?.form) {
          this.formErrors = e.messages.form;
        }
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
    }
    this.submitInProgress = false;
  }
}
