import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { FormErrors } from '../../../shared/api/response.error';
import { SpenModule } from '@classi/spen';

@Component({
  standalone: true,
  imports: [ReactiveFormsModule, SpenModule],
  selector: 'app-bc-login-manabi-begin-form',
  templateUrl: './bc-login-manabi-begin-form.component.html',
  styleUrls: ['./bc-login-manabi-begin-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BcLoginManabiBeginFormComponent implements AfterViewInit {
  @Input()
  formErrors!: FormErrors | null;
  @Input()
  submitInProgress!: boolean;

  @Output()
  login = new EventEmitter<{ username: string; password: string }>();

  @ViewChild('idInput', { read: ElementRef, static: false })
  idInput: ElementRef<HTMLElement> | null = null;

  readonly form = this.fb.group({
    username: this.fb.control('', {
      // TODO: 文字種のバリデーションがあれば追加(記号、全角、長さ)
      validators: [Validators.required],
    }),
    password: this.fb.control('', {}),
  });

  constructor(private readonly fb: UntypedFormBuilder) {}

  ngAfterViewInit(): void {
    // ページを開いた時にID入力欄にフォーカス
    if (this.idInput) {
      this.idInput.nativeElement.focus();
    }
  }

  onSubmit() {
    this.login.emit(this.form.value);
  }
}
