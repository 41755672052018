import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { PageEvent } from '@classi/spen';
import { PageParams, SsoUsersList } from '../../../shared/api/types';
import { SsoUsersAccountEditDialogComponent } from './account-edit-dialog/sso-users-account-edit-dialog.component';

@Component({
  selector: 'app-sso-users-list',
  templateUrl: './sso-users-list.component.html',
  styleUrls: ['./sso-users-list.component.scss'],
})
export class SsoUsersListComponent {
  @Input()
  accountType!: 'teacher' | 'student';

  @Input()
  usersList!: SsoUsersList;

  @Output()
  pageChange = new EventEmitter<PageParams>();

  @Output()
  ssoUserUpdate = new EventEmitter<void>();

  @ViewChild(SsoUsersAccountEditDialogComponent, { static: true })
  accountEditDialog!: SsoUsersAccountEditDialogComponent;

  constructor() {}

  onPage(page: PageEvent) {
    this.pageChange.emit({
      per_page: 10,
      page: page.index + 1,
    });
  }
}
