import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { isPermittedBackurl } from '../pages/login-complete/is-permitted-back-url';

@Injectable({
  providedIn: 'root',
})
export class BackurlService {
  private readonly cookieName = 'url_after_sign_in';
  private readonly domainName = environment.classiCookieDomain;
  constructor() {}

  save(url: string): void {
    const encodeUri = encodeURIComponent(url);
    document.cookie = `${this.cookieName}=${encodeUri}; domain=${this.domainName}; secure`;
  }

  get(): string | null {
    const backurlCookie = document.cookie
      .split('; ')
      .find((row) => row.startsWith(this.cookieName));

    if (backurlCookie === undefined) {
      return null;
    }

    const url = decodeURIComponent(backurlCookie.split('=')[1]);
    const backurl = isPermittedBackurl(url) ? url : null;

    return backurl;
  }

  delete(): void {
    document.cookie = `${this.cookieName}=; domain=${this.domainName}; max-age=0`;
  }

  restore(): string | null {
    const backurlCookie = document.cookie
      .split('; ')
      .find((row) => row.startsWith(this.cookieName));

    // 取り出したらcookieを削除する
    document.cookie = `${this.cookieName}=; domain=${this.domainName}; max-age=0;`;

    if (backurlCookie === undefined) {
      return null;
    }

    const url = decodeURIComponent(backurlCookie.split('=')[1]);
    const backurl = isPermittedBackurl(url) ? url : null;

    return backurl;
  }
}
