import { CurrentFilterSet, UsersSearchParams } from '../../shared/api/types';

export function changeUsersSearchParams(
  params: CurrentFilterSet
): UsersSearchParams {
  const userSearchParams: UsersSearchParams = {
    school_year: params.school_year as number,
    stage_id: params.stage_id as number,
    grade_id: params.grade_id as number,
    classroom_id: params.classroom_id as number,
    attendance_number: params.attendance_number as number,
    email: params.email as string,
  };

  return userSearchParams;
}
