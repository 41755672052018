import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SavedUsernameService {
  usernameKey = 'username';
  constructor() {}

  setId(id: string): void {
    try {
      localStorage.setItem(this.usernameKey, id);
    } catch {
      return;
    }
  }

  getId(): string | null {
    try {
      return localStorage.getItem(this.usernameKey);
    } catch {
      return null;
    }
  }

  removeId(): void {
    try {
      localStorage.removeItem(this.usernameKey);
    } catch {
      return;
    }
  }
}
