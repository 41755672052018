<form (submit)="onSubmit()" [formGroup]="form">
  <div class="form-field">
    <spen-label>Classi ID</spen-label>
    <div>
      <spen-form-field>
        <input
          spenInput
          formControlName="new_username"
          placeholder="入力してください"
          autocapitalize="off"
          autocorrect="off"
        />
      </spen-form-field>
      <spen-form-error
        *ngIf="this.formErrors?.new_username as newUsernameError"
        >{{ newUsernameError }}</spen-form-error
      >
    </div>
  </div>
  <div class="form-actions">
    <button
      color="primary"
      spenButton
      class="action-button"
      [disabled]="form.invalid || submitInProgress"
    >
      変更する
    </button>
  </div>
</form>

<app-confirm-password-dialog
  [title]="'Classi IDの変更'"
  (confirmed)="retryOnSubmit()"
></app-confirm-password-dialog>
